import React, { FC, lazy } from 'react'
const ElvisToast = lazy(() => import('./ElvisToast'))

export const Toast: FC = () => {
  if (typeof window === 'undefined' || typeof document === 'undefined')
    return <div></div>
  return (
    <React.Suspense fallback={<div />}>
      <ElvisToast />
    </React.Suspense>
  )
}
