import { WebStorageStateStore } from 'oidc-client-ts'
import React, { ComponentType, PropsWithChildren } from 'react'
import { AuthProvider, AuthProviderProps } from 'react-oidc-context'

import { MonitorSession } from './MonitorSession'
import { SilentRenew } from './SilentRenew'

export const isBrowser = typeof window !== 'undefined' && window

export const ElvidProvider: ComponentType<PropsWithChildren> = ({
  children,
}) => {
  let oidcConfig: AuthProviderProps = {
    client_id: process.env.ELVID_CLIENT_ID || '',
    redirect_uri: '',
    scope: process.env.ELVID_SCOPE || '',
    authority: process.env.ELVID_AUTHORITY || '',
    post_logout_redirect_uri: '',
    automaticSilentRenew: false, // Functionality doesn't work in Firefox due to cookies being blocked. Alternative solution implemented in SilentRenew.tsx
    monitorSession: false, // Functionality doesn't work in Safari and Edge due to cookies being blocked. Alternative solution implemented: check if localStorage has accessToken when tab has focus (MonitorSession.tsx).
    loadUserInfo: true,
  }

  if (isBrowser) {
    oidcConfig = {
      ...oidcConfig,
      redirect_uri: `${window.location.origin}/auth/signin`,
      post_logout_redirect_uri: window.location.origin,
      onSigninCallback: () => {
        isBrowser &&
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          )
      },
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      stateStore: new WebStorageStateStore({ store: window.localStorage }),
    }
  }

  return (
    <AuthProvider {...oidcConfig}>
      {isBrowser && <MonitorSession />}
      {isBrowser && <SilentRenew />}
      {children}
    </AuthProvider>
  )
}
