import React from 'react'
import ReactDOM from 'react-dom/client'

import { Toast } from '@elvia/ui/src/components/Toast/Toast'
import './src/styles/globals.scss'

export const wrapPageElement = ({ element }, config) => {
  return (
    <>
      {!config.skipToastElement && <Toast />}
      {element}
    </>
  )
}

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
