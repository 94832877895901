exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-signin-tsx": () => import("./../../../src/pages/auth/signin.tsx" /* webpackChunkName: "component---src-pages-auth-signin-tsx" */),
  "component---src-pages-auth-signout-tsx": () => import("./../../../src/pages/auth/signout.tsx" /* webpackChunkName: "component---src-pages-auth-signout-tsx" */),
  "component---src-pages-dev-tsx": () => import("./../../../src/pages/dev.tsx" /* webpackChunkName: "component---src-pages-dev-tsx" */),
  "component---src-pages-effekttariff-tsx": () => import("./../../../src/pages/effekttariff.tsx" /* webpackChunkName: "component---src-pages-effekttariff-tsx" */),
  "component---src-pages-elvia-kart-tsx": () => import("./../../../src/pages/elvia-kart.tsx" /* webpackChunkName: "component---src-pages-elvia-kart-tsx" */),
  "component---src-pages-forbruksapp-signin-tsx": () => import("./../../../src/pages/forbruksapp/signin.tsx" /* webpackChunkName: "component---src-pages-forbruksapp-signin-tsx" */),
  "component---src-pages-minside-index-tsx": () => import("./../../../src/pages/minside/index.tsx" /* webpackChunkName: "component---src-pages-minside-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-form-tsx": () => import("./../../../src/templates/Form.tsx" /* webpackChunkName: "component---src-templates-form-tsx" */),
  "component---src-templates-front-page-tsx": () => import("./../../../src/templates/FrontPage.tsx" /* webpackChunkName: "component---src-templates-front-page-tsx" */),
  "component---src-templates-main-category-tsx": () => import("./../../../src/templates/MainCategory.tsx" /* webpackChunkName: "component---src-templates-main-category-tsx" */)
}

