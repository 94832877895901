import React, { PropsWithChildren, ReactNode } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { CookiesProvider } from '@elvia/cookies'
import { ElvidProvider } from '@elvia/elvid-provider'
import { ApiProvider } from '@elvia/kundeportal-api'
import { AppInsightsProvider } from '@elvia/analytics'

import { CustomerParamsProvider } from '@elvia/minside-routing'

interface ProvidersProps {
  children?: ReactNode
}

export const Providers: React.ComponentType<
  PropsWithChildren<ProvidersProps>
> = props => {
  // const terms = useTerms()
  const siteKey = process.env.RECAPTCHA_SITEKEY ?? ''
  return (
    <AppInsightsProvider>
      <ElvidProvider>
        <CustomerParamsProvider>
          <ApiProvider>
            <CookiesProvider>
              <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                {props.children}
              </GoogleReCaptchaProvider>
            </CookiesProvider>
          </ApiProvider>
        </CustomerParamsProvider>
      </ElvidProvider>
    </AppInsightsProvider>
  )
}
