import dayjs from 'dayjs'
import { map, max, prop } from 'ramda'
import { useQuery, useMutation } from '@tanstack/react-query'

import { useAuth } from '@elvia/elvid-provider'
import {
  ClientContractDto,
  ClientMeteringPointDto,
  ClientMeteringPointPowerSupplierDto,
} from './kundeportal-api'
import { useKundeportalApi } from './ApiProvider'
import { createFullAddress } from './createFullAddress'
import { createContractPostalCodeAndCity } from './createPostalCodeAndCity'
import { capitalize } from '@elvia/ui/src/utils/capitalize'
import { useCustomerParams } from '@elvia/minside-routing'

export interface Contract extends ClientContractDto {
  isActive: boolean
  fullAddress: string
  postalCodeAndCity: string
  activePowerSupplier: ClientMeteringPointPowerSupplierDto | null
  previousPowerSupplier: ClientMeteringPointPowerSupplierDto | null
  meteringPointDescription: string | null
  activeYears: string[]
  isStandardProduct: boolean
}

const isActive = (
  powerSupplier: ClientMeteringPointPowerSupplierDto
): boolean => {
  const now = dayjs()
  return (
    powerSupplier.endDate == null || dayjs(powerSupplier.endDate).isAfter(now)
  )
}

const getActivePowerSupplier = (
  contract: ClientContractDto
): ClientMeteringPointPowerSupplierDto | null => {
  const powerSuppliers = contract?.meteringPoint?.meteringPointPowerSuppliers
  const activePowerSuppliers = powerSuppliers?.find(ps => isActive(ps))

  return activePowerSuppliers !== undefined ? activePowerSuppliers : null
}

const getPreviousPowerSupplier = (
  contract: ClientContractDto
): ClientMeteringPointPowerSupplierDto | null => {
  const powerSuppliers = contract?.meteringPoint?.meteringPointPowerSuppliers
  const inactivePowerSuppliers = powerSuppliers?.filter(ps => !isActive(ps))

  const mostRecentInactivePowerSupplier = inactivePowerSuppliers?.length
    ? inactivePowerSuppliers.reduce((previous, current) =>
        previous.endDate &&
        current.endDate &&
        previous.endDate > current.endDate
          ? previous
          : current
      )
    : null

  return mostRecentInactivePowerSupplier
}

const getMeteringPointDescription = (
  description: string | null | undefined
): string | null => (description ? capitalize(description) : null)

const getActiveYearsForContract = (
  contract: ClientContractDto | undefined
): string[] => {
  if (!contract) return []
  const contractStartTime = dayjs(contract?.startTime)
  const contractEndTime = contract?.endTime ? dayjs(contract?.endTime) : null
  const limitYear = max(dayjs().subtract(5, 'year').year(), 2019)
  const endYear = contractEndTime ? contractEndTime.year() : dayjs().year()
  const startYear =
    contractStartTime.year() >= limitYear ? contractStartTime.year() : limitYear

  const years: string[] = []

  for (let i = startYear; i <= endYear; i++) {
    years.push(dayjs(`${i}`).format('YYYY'))
  }

  return years
}

const getIsStandardProduct = (contract: ClientContractDto) => {
  const activeProduct = contract?.products?.find(product => product.isActive)

  if (!activeProduct) {
    const product = contract?.products?.find(
      product => product.name === 'HN ELHA avr'
    )

    if (product?.endTime === '2022-06-29T22:00:00Z') return true
    return false
  }

  return !!activeProduct.isStandard
}

const transformContract: (
  contract: ClientContractDto
) => Contract = contract => {
  return {
    ...contract,
    isActive: contract.endTime === null,
    fullAddress: contract.meteringPoint?.address
      ? createFullAddress(contract.meteringPoint.address)
      : '',
    postalCodeAndCity: contract.meteringPoint?.address
      ? createContractPostalCodeAndCity(contract.meteringPoint.address)
      : '',
    activePowerSupplier: getActivePowerSupplier(contract),
    previousPowerSupplier: getPreviousPowerSupplier(contract),
    meteringPointDescription: getMeteringPointDescription(
      contract.meteringPoint?.meteringPointDescription
    ),
    activeYears: getActiveYearsForContract(contract),
    isStandardProduct: getIsStandardProduct(contract),
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useBankAccountNumberMutation = () => {
  const kundePortalApi = useKundeportalApi()
  const { data: selectedContract } = useSelectedContract()

  return useMutation({
    mutationFn: (bankAccountNumber: string | null) => {
      if (!selectedContract) throw new Error('No contract')

      return kundePortalApi.contract.bankaccountnumberCreate(
        selectedContract.id,
        {
          bankAccountNumber,
        }
      )
    },
  })
}

export const useContracts = <T = Contract[]>(
  select?: (data: Contract[]) => T,
  customerId?: string
): ReturnType<typeof useQuery<Contract[], Error, T>> => {
  const kundePortalApi = useKundeportalApi()
  const { isAuthenticated } = useAuth()
  const { customerId: selectedCustomerId } = useCustomerParams()
  const customerIdToUse = customerId || selectedCustomerId

  const fetchContracts = () => {
    if (!customerIdToUse) throw new Error('No customer id')

    return kundePortalApi.customer
      .contractDetail(customerIdToUse, {
        PageSize: 5000,
      })
      .then(prop('data'))
      .then(map(transformContract))
  }

  return useQuery({
    queryKey: ['useContracts', customerIdToUse],
    queryFn: fetchContracts,
    enabled: !!(isAuthenticated && customerIdToUse),
    select,
  })
}

export const useSelectedContract = <T = Contract | null>(
  select?: (data: Contract | null) => T
): ReturnType<typeof useQuery<Contract | null, Error, T>> => {
  const { contractId } = useCustomerParams()
  const { data: contracts, isSuccess } = useContracts()

  return useQuery<Contract | null, Error, T>({
    queryKey: ['selectedContract', contractId],
    queryFn: () => {
      return contracts?.find(contract => contract.id === contractId) || null
    },
    enabled: isSuccess,
    select,
  })
}

export const selectHasMeteringPointDescription = (
  data: Contract[]
): boolean => {
  return data
    ? data.some(
        contract => contract.meteringPoint?.meteringPointDescription !== null
      )
    : false
}

export const selectActiveMeteringPoints = (data: Contract[]): Contract[] => {
  return data.filter(contract => contract.isActive)
}

const isDefined = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined
}

interface IGetMeteringPoint {
  address: ClientMeteringPointDto['address']
  id: NonNullable<ClientMeteringPointDto['meteringPointId']>
  serialNumber: ClientMeteringPointDto['meterSerialNumber']
  type: ClientMeteringPointDto['meteringPointTypeValue']
  productionSharing: {
    id?: ClientMeteringPointDto['productionSharingContractId']
    type?: ClientMeteringPointDto['productionSharingMeteringPointType']
  }
}

export const getMeteringPoint = (
  data: Contract | null
): IGetMeteringPoint | undefined => {
  const meteringPoint = data?.meteringPoint

  if (!isDefined(meteringPoint) || !isDefined(meteringPoint.meteringPointId))
    return undefined

  return {
    address: meteringPoint.address,
    id: meteringPoint.meteringPointId,
    serialNumber: meteringPoint.meterSerialNumber,
    type: meteringPoint.meteringPointTypeValue,
    productionSharing: {
      id: meteringPoint.productionSharingContractId,
      type: meteringPoint.productionSharingMeteringPointType,
    },
  }
}
