import {
  always,
  complement,
  either,
  evolve,
  ifElse,
  isEmpty,
  isNil,
  join,
  pick,
  pipe,
  propSatisfies,
  props,
  replace,
  toLower,
  toUpper,
  trim,
  values,
} from 'ramda'

import { AddressDto, ClientMeteringPointAddressDto } from './kundeportal-api'
import { Address } from './Address'

const hasValue = complement(either(isEmpty, isNil))

export const createCustomerPostalCodeAndCity = (
  address: AddressDto | null
): string => {
  const city = pipe(toLower, replace(/^./, toUpper))(address?.city || '')
  const postalCodeAndCity = `${address?.postCode} ${city}`
  return postalCodeAndCity.trim()
}

const contractHasPostCodeOrCity = either(
  propSatisfies(hasValue, 'zipCode'),
  propSatisfies(hasValue, 'zipPlace')
)

export const createContractPostalCodeAndCity = ifElse(
  contractHasPostCodeOrCity,
  pipe<never, Address, string[], string, string>(
    evolve({ zipPlace: pipe(toLower, replace(/^./, toUpper)) }),
    props(['zipCode', 'zipPlace']),
    join(' '),
    trim
  ),
  always('')
) as unknown as (
  value: ClientMeteringPointAddressDto | null | undefined
) => string

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createZipCodeAndCity = pipe<any, Record<string, string>, string>(
  pick(['addressZipCode', 'addressZipPlace']),
  ifElse(
    propSatisfies(either(isEmpty, isNil), 'addressZipCode'),
    always('') as never,
    pipe(values, join(' '))
  )
)
