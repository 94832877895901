import React, {
  createContext,
  useContext,
  // ComponentClass,
  ComponentType,
  PropsWithChildren,
} from 'react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import {
  ReactPlugin,
  // withAITracking,
} from '@microsoft/applicationinsights-react-js'
import { globalHistory } from '@reach/router'

export const reactPlugin = new ReactPlugin()

const ai = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY || '',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory },
    },
    enableAutoRouteTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
  },
})
ai.loadAppInsights()
export const appInsights = ai.appInsights

const AppInsightsContext = createContext(reactPlugin)

// withAITracking creates hydration errors but we are not using it
// for anything specific right now anyway
// export const WithAppInsights = (
//   Component: ComponentType<PropsWithChildren>
// ): ComponentClass => withAITracking(reactPlugin, Component)

// const WrappedWithAppInsights = WithAppInsights(({ children }) => {
//   return <>{children}</>
// })

const AppInsightsProvider: ComponentType<PropsWithChildren> = ({
  children,
}) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {/* <WrappedWithAppInsights>{children}</WrappedWithAppInsights> */}
      {children}
    </AppInsightsContext.Provider>
  )
}

const useAppInsights = (): ReactPlugin => useContext(AppInsightsContext)
export { AppInsightsProvider, useAppInsights }
