import React, { ComponentType, ErrorInfo, PropsWithChildren } from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps,
} from 'react-error-boundary'
import { trackException } from './logger'

export const ErrorBoundary: ComponentType<
  PropsWithChildren<ErrorBoundaryProps>
> = props => {
  const { onError, ...rest } = props

  const handleError = (error: Error, info: ErrorInfo) => {
    if (onError) onError(error, info)
    trackException(error, info)
  }

  return (
    <ReactErrorBoundary onError={handleError} {...rest}>
      {props.children}
    </ReactErrorBoundary>
  )
}
