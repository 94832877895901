module.exports = [{
      plugin: require('../../../features/salesforce-chat/gatsby-browser.js'),
      options: {"plugins":[],"environment":"production","blackList":["/elvia-kart/"],"salesForceBaseUrl":"https://elvia.my.site.com"},
    },{
      plugin: require('../../../features/poweroutagemap/gatsby-browser.js'),
      options: {"plugins":[],"environment":"production","url":"/elvia-kart","skipToastElement":true},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-c06689e30e/2/.yarn/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-45d59a4aa5.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"elvia-no","short_name":"hn","start_url":"/","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"967b41c47f5fcbd1a13e0a1a5a2b2a6c"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-019712d2f6/2/.yarn/gatsby-plugin-google-tagmanager-npm-5.13.1-f82e0c3055-e4d7e6e0c9.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5ZNFG7V","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"web","environment":"production"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-e4349fca3d/2/.yarn/gatsby-plugin-catch-links-npm-5.13.1-d0715a6524-eb88878106.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-19d20139ac/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
