import { isBrowser } from './elvid-provider'

export function getStorage(value: string): string | null {
  if (isBrowser) {
    return sessionStorage.getItem(value)
  }
  return null
}

export function setStorage(key: string, value: string): void {
  if (isBrowser) {
    sessionStorage.setItem(key, value)
  }
}

export function removeStorage(value: string): void {
  if (isBrowser) {
    sessionStorage.removeItem(value)
  }
}
