import { FetchStatus, QueryStatus } from '@tanstack/react-query'
import { AsyncStatus } from './AsyncStatus'

export const createStatus = <T>(
  data: T | undefined,
  error: Error | undefined | null,
  isFetching = true
): Extract<
  AsyncStatus,
  'empty' | 'rejected' | 'fulfilled' | 'pending' | 'idle'
> => {
  if (error?.message === 'NoContent') return 'empty'
  if (error) return 'rejected'
  if (data) return 'fulfilled'
  if (isFetching) return 'pending'
  return 'idle'
}

export const createCombinedStatus = (
  error: Error | undefined,
  fetchStatus: FetchStatus,
  status: QueryStatus
): AsyncStatus => {
  if (status === 'success' && error?.message === 'NoContent') return 'empty'
  if (status === 'error') return 'error'
  if (status === 'success') return 'success'
  if (fetchStatus === 'fetching') return 'fetching'
  if (fetchStatus === 'paused') return 'paused'
  return 'idle'
}
