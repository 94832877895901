import { useQuery } from '@elvia/kundeportal-api'
import React, { FC, createContext, useEffect } from 'react'

export enum TypeOfCookie {
  Functional = 'C0003',
}

type UseCookieConsentCheck = (type: TypeOfCookie) => {
  isCookieConsented: boolean
}

declare const window: {
  OptanonWrapper?: () => void
  OneTrust: {
    OnConsentChanged: (cb: () => void) => void
  }
  OptanonActiveGroups?: string
} & Window

type CookiesProviderProps = { children: React.ReactNode }

const CookiesContext = createContext<string | undefined>(undefined)

const useCookies = () => {
  return useQuery({
    queryKey: ['cookies'],
    queryFn: async () => {
      const cookies = window.OptanonActiveGroups
      const handler = window.OneTrust

      if (!cookies || !handler) throw new Error('Cookies not ready')

      const resizeObserver = new ResizeObserver(() => {
        const cookieDiv = window.document.getElementById('onetrust-banner-sdk')
        const fixedBottomContainer = window.document.getElementById(
          'fixed-gatsby-container'
        )
        if (!cookieDiv || !fixedBottomContainer) return

        const fixedBottomContainerHeight =
          fixedBottomContainer.getBoundingClientRect().height

        cookieDiv.style.setProperty(
          'bottom',
          `calc(${fixedBottomContainerHeight}px + 3rem)`,
          'important'
        )
      })
      const fixedBottomContainer = window.document.getElementById(
        'fixed-gatsby-container'
      )

      if (fixedBottomContainer) {
        resizeObserver.observe(fixedBottomContainer)
      }

      return cookies
    },
    retry: 5,
  })
}

export const CookiesProvider: FC<CookiesProviderProps> = ({ children }) => {
  const { data: cookies, status, refetch } = useCookies()

  useEffect(() => {
    if (status === 'success' && typeof window !== 'undefined') {
      window.OneTrust.OnConsentChanged(refetch)
    }
  }, [refetch, status])

  return (
    <CookiesContext.Provider value={cookies}>
      {children}
    </CookiesContext.Provider>
  )
}

export const useCookieConsentCheck: UseCookieConsentCheck = type => {
  const context = React.useContext(CookiesContext)

  const isCookieConsented = context?.includes(type) || false

  return { isCookieConsented }
}
