export const ensureStablePath = (path: string): string => {
  return path
    .split('/')
    .filter(x => x)
    .join('/')
}

export const slicePath = (path: string, sliceCount: number): string => {
  return path.split('/').slice(0, sliceCount).join('/')
}
