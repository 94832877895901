import { navigate, RouteComponentProps } from '@reach/router'
import React, { FC, ReactNode, useEffect } from 'react'

import { useAuth, setStorage } from '@elvia/elvid-provider'

export interface RouteIdProps {
  customerId?: string
  contractId?: string
}

interface PrivateRouteProps extends RouteComponentProps<RouteIdProps> {
  component: FC<RouteComponentProps>
  children?: ReactNode
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  location,
  ...rest
}) => {
  const { isAuthenticated, isLoading, clearStaleState } = useAuth()

  useEffect(() => {
    if (!isAuthenticated && !isLoading && location) {
      setStorage('loginRedirectUri', location.pathname)

      clearStaleState().then(() => {
        navigate('/logg-inn/')
      })
    }
  }, [clearStaleState, isAuthenticated, isLoading, location])

  return isAuthenticated ? <Component location={location} {...rest} /> : null
}
