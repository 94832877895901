const initEmbeddedMessaging = () => {
  try {
    const { embeddedservice_bootstrap } = window

    embeddedservice_bootstrap.settings.language = 'no'

    embeddedservice_bootstrap.init(
      process.env.SALESFORCE_BOOTSTRAP_ID,
      'Chat',
      `${process.env.SALESFORCE_BASE_URL_2}/${process.env.SALESFORCE_BASE_ESW}`,
      {
        scrt2URL: process.env.SALESFORCE_SCRT2_URL,
      }
    )
    embeddedservice_bootstrap?.utilAPI?.hideChatButton()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error loading Embedded Messaging: ', err)
  }
}

export const initSalesforce = () => {
  const { embeddedservice_bootstrap } = window

  embeddedservice_bootstrap?.utilAPI?.hideChatButton()

  if (!embeddedservice_bootstrap) {
    const baseUrl = process.env.SALESFORCE_BASE_URL_2
    const esw = process.env.SALESFORCE_BASE_ESW
    const s = document.createElement('script')
    s.setAttribute('src', baseUrl + '/' + esw + '/assets/js/bootstrap.min.js')
    s.onload = function () {
      initEmbeddedMessaging()
    }
    document.body.appendChild(s)
  } else {
    initEmbeddedMessaging()
  }
}
