import { FC, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'

import { getAccessTokenFromLocalStorage } from './getAccessTokenFromLocalStorage'

const useMonitorSession = (): void => {
  // handle signout from separate tab (alternative to monitorSession)
  const { isAuthenticated, removeUser, signoutRedirect, clearStaleState } =
    useAuth()
  const accessToken = getAccessTokenFromLocalStorage()

  const [tabHasFocus, setTabHasFocus] = useState<boolean>(true)
  useEffect(() => {
    const handleFocus = () => {
      setTabHasFocus(true)
    }

    const handleBlur = () => {
      setTabHasFocus(false)
    }

    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && !accessToken && tabHasFocus) {
      removeUser()
      signoutRedirect()
      clearStaleState()
    }
  }, [isAuthenticated, accessToken, tabHasFocus])
}

export const MonitorSession: FC = () => {
  useMonitorSession()

  return null
}
