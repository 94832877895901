import {
  join,
  pipe,
  pick,
  ifElse,
  either,
  values,
  propSatisfies,
  isEmpty,
  isNil,
  always,
} from 'ramda'

import {
  ClientMeteringPointAddressDto,
  AddressDto,
  EligibleProductionSharingMeteringPointDto,
  ProductionSharingMeteringPointDto,
} from '@elvia/kundeportal-api'
import { Address } from './Address'

export const createFullAddress = pipe<never, Record<string, string>, string>(
  pick(['streetName', 'buildingNumber', 'letter']),
  ifElse(
    propSatisfies(either(isEmpty, isNil), 'streetName'),
    always(''),
    pipe(values, join(' '))
  ) as unknown as () => string
) as unknown as (
  value: ClientMeteringPointAddressDto | AddressDto | Address | undefined | null
) => string

export const createFullAddressWithApartmentNumber = pipe<
  never,
  Record<string, string>,
  string
>(
  pick([
    'addressStreetName',
    'addressBuildingNumber',
    'addressLetter',
    'addressApartmentNumber',
  ]),
  ifElse(
    propSatisfies(either(isEmpty, isNil), 'addressStreetName'),
    always(''),
    pipe(values, join(' '))
  ) as unknown as () => string
) as unknown as (
  value:
    | EligibleProductionSharingMeteringPointDto
    | ProductionSharingMeteringPointDto
) => string
