import { FC, useEffect } from 'react'
import { useAuth } from 'react-oidc-context'

import { setStorage } from './browserStorage'

export const SilentRenew: FC = () => {
  const { events, signinSilent, signinRedirect } = useAuth()

  useEffect(() => {
    return events.addAccessTokenExpiring(() => {
      signinSilent().catch(() => {
        setStorage('loginRedirectUri', location.pathname)
        signinRedirect()
      })
    })
  }, [events])

  return null
}
