import { User } from 'oidc-client-ts'

const authority = process.env.ELVID_AUTHORITY
const clientId = process.env.ELVID_CLIENT_ID

const getUser = (): User | null => {
  const oidcStorage = localStorage.getItem(`oidc.user:${authority}:${clientId}`)

  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}

export const getAccessTokenFromLocalStorage = (): string => {
  const user = getUser()
  return user?.access_token ?? ''
}
