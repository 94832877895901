import { F, pipe, tryCatch, unless } from 'ramda'

const formatNumber = (phoneNumber: string) => {
  if (phoneNumber && phoneNumber.match('^[+]?[0-9 ]+$')) {
    const countryCode = phoneNumber.slice(0, 3)

    const phoneNumberWithoutContryCode = phoneNumber.slice(3)

    const phoneNumberWithoutWhiteSpace = phoneNumberWithoutContryCode.replace(
      / /g,
      ''
    )

    const splitNumberInGroupsOfTwo = phoneNumberWithoutWhiteSpace
      .match(/..?/g)
      ?.join(' ')

    return [countryCode, splitNumberInGroupsOfTwo].join(' ')
  }
  return ''
}

const doesStringContainCountryCode = (phoneNumber: string): boolean => {
  return tryCatch(() => phoneNumber.charAt(0) === '+', F)()
}

const addCountryCode = (phoneNumber: string) => {
  return phoneNumber ? '+47' + phoneNumber : ''
}

export const formatPhoneNumber = pipe(
  unless(doesStringContainCountryCode, addCountryCode),
  formatNumber
)
