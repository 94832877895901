/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccessDto {
  /** @format uuid */
  id: string
  role: Role
  /** @format uuid */
  customerId: string
  /** @format date-time */
  lastAccessedDateUtc: string | null
  isDeleted: boolean
  /** @format date-time */
  deletedDate: string | null
  name: string | null
  canBeRevoked: boolean
  /** @format date-time */
  expirationDateUtc: string | null
  /** @format date-time */
  createdDateUtc: string
  /** @format uuid */
  grantedByAccessId: string | null
}

export interface AccessInfoDto {
  customers: CustomerAccessInfoDto[] | null
}

export interface AccessRequestErrorDto {
  errorType: AccessRequestErrorType
  errorMessage: string | null
}

export enum AccessRequestErrorType {
  TooManyInvalidCombinations = 'TooManyInvalidCombinations',
  InvalidCombination = 'InvalidCombination',
  AlreadyAdded = 'AlreadyAdded',
  BadCombinationRequest = 'BadCombinationRequest',
}

export interface AccessRequestIncomingDto {
  customerNumber: string | null
  meterSerialNumber: string | null
  mobileNumber: string | null
  mobileNumberSender: string | null
}

export interface AccessRequestOutgoingDto {
  /** @format uuid */
  id: string
  /** @format uuid */
  customerReceivingRequestId: string
  status: AccessRequestStatus
  contractIds: string[] | null
}

export enum AccessRequestStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Revoked = 'Revoked',
}

export interface AccessRequestStatusDto {
  status: AccessRequestStatus
  contractIds: string[] | null
}

export interface AccessRequestsOutgoingDto {
  name: string | null
  /** @format date-time */
  lastAccessedDateUtc: string | null
  accessRequests: AccessRequestOutgoingDto[] | null
}

export interface AccessRequestsRevokedOutgoingDto {
  name: string | null
  /** @format date-time */
  lastAccessedDateUtc: string | null
  /** @format date-time */
  createdDateUtc: string | null
  /** @format date-time */
  updatedDateUtc: string | null
  role: Role
  status: AccessRequestStatus
}

export interface AccessStatusDto {
  status: AccessRequestStatus
}

export interface AccessesDto {
  accesses: AccessDto[] | null
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export enum AccountNumberStatus {
  Unknown = 'Unknown',
  Missing = 'Missing',
  NoAccess = 'NoAccess',
  Ok = 'Ok',
}

export interface AddressDto {
  /** @format uuid */
  id: string
  streetName: string | null
  streetCode: string | null
  buildingNumber: string | null
  floor: string | null
  room: string | null
  postCode: string | null
  city: string | null
  citySubDivision: string | null
  municipality: string | null
  countryCode: string | null
  postBox: string | null
  careOf: string | null
  attentionOf: string | null
  onBehalf: string | null
}

export interface AverageSpotPriceUnitDto {
  total: string | null
  totalExVat: string | null
  totalExTaxes: string | null
}

export interface AverageSpotPriceValueDto {
  /** @format double */
  total: number
  /** @format double */
  totalExTaxes: number
  /** @format double */
  taxes: number
  unitOfMeasure: string | null
}

export interface BankAccountNumberDto {
  bankAccountNumber: string | null
}

export interface BankAccountNumberEntity {
  id: string | null
  customerMissingBankAccountNumberId: string | null
  bankAccountNumber: string | null
  contractId: string | null
  /** @minLength 1 */
  customerId: string
  cabCustomerNumber: string | null
  elwinCustomerNumber: string | null
  stangeCustomerNumber: string | null
  createdBySubId: string | null
  cabInvoiceConfiguration: string | null
  elwinReskontronummer: string | null
  stangeReskontronummer: string | null
  updatedBySubId: string | null
  /** @format date-time */
  createdDateUtc: string
  /** @format date-time */
  lastUpdatedDateUtc: string
  meteringPointId: string | null
  isExported: boolean
}

export interface Bidragsytermaalepunkt {
  maalepunktId: string | null
}

export interface ClientAccessRequestBySmsIncomingDto {
  /** @minLength 1 */
  mobileNumber: string
  mobileNumberSender: string | null
}

export interface ClientAccessRequestBySmsOutgoingDto {
  /** @format uuid */
  id: string
  /** @format uuid */
  customerReceivingRequestId: string
  name: string | null
  contractIds: string[] | null
  status: string | null
  /** @format date-time */
  createdDateUtc: string
  /** @minLength 1 */
  mobileNumber: string
  mobileNumberSender: string | null
}

export interface ClientAccessRequestsBySmsOutgoingDto {
  name: string | null
  mobileNumberSender: string | null
  accessRequests: ClientAccessRequestBySmsOutgoingDto[] | null
}

export interface ClientAccountNumberDto {
  value: string | null
  status: AccountNumberStatus
}

export interface ClientContractDto {
  /** @format uuid */
  id: string
  /** @format uuid */
  customerId: string
  /** @format date-time */
  startTime: string
  /** @format date-time */
  endTime: string | null
  invoiceAddress: AddressDto | null
  meteringPoint: ClientMeteringPointDto | null
  hanPortStatus: HanPortStatus | null
  lastHanPortEvent: HanPortEventOutgoingDto | null
  products: ClientContractProductDto[] | null
  contractNotificationPreferences: ClientContractNotificationPreferenceDto[] | null
  contractPowerSuppliers: ClientContractPowerSupplierDto[] | null
  hasCompensation: boolean
  hasConsumptionThreshold: boolean
  accountNumber: ClientAccountNumberDto | null
  commercialCode: string | null
  consumptionCode: string | null
  status: string | null
}

export interface ClientContractNotificationPreferenceDto {
  /** @format uuid */
  id: string
  salesforceId: string | null
  /** @format uuid */
  contractId: string
  elhubMobile: string | null
  elhubEmail: string | null
  mobile: string | null
  email: string | null
  type: NotificationType
  source: NotificationSource | null
}

export interface ClientContractPowerSupplierDto {
  /** @format uuid */
  contractId: string
  powerSupplierId: string | null
  powerSupplierName: string | null
  /** @format date-time */
  startDate: string
  /** @format date-time */
  endDate: string | null
}

export interface ClientContractProductDto {
  /** @format uuid */
  id: string
  name: string | null
  /** @format date-time */
  startTime: string
  /** @format date-time */
  endTime: string | null
  isActive: boolean
  isStandard: boolean
}

export interface ClientCustomerDto {
  /** @format uuid */
  id: string
  type: CustomerType
  identificationNumber: string | null
  firstName: string | null
  lastName: string | null
  name: string | null
  cabCustomerNumber: string | null
  elwinCustomerNumber: string | null
  stangeCustomerNumber: string | null
  role: Role | null
  /** @format date-time */
  lastAccessedDateUtc: string | null
  customerNumbers: string[] | null
  customerNumber: string | null
  permissions: Permission[] | null
}

export interface ClientMeteringPointAddressDto {
  streetName: string | null
  buildingNumber: string | null
  letter: string | null
  floor: string | null
  apartmentNumber: string | null
  zipCode: string | null
  zipPlace: string | null
  knr: string | null
  /** @format int32 */
  gnr: number | null
  /** @format int32 */
  bnr: number | null
  /** @format int32 */
  fnr: number | null
  /** @format int32 */
  snr: number | null
}

export interface ClientMeteringPointDto {
  meteringPointId: string | null
  meterSerialNumber: string | null
  meteringPointTypeValue: MeteringPointType
  isAms: boolean
  communicationModule: string | null
  meteringPointDescription: string | null
  isRemoteRead: boolean
  address: ClientMeteringPointAddressDto | null
  /** @format double */
  latitude: number | null
  /** @format double */
  longitude: number | null
  /** @format uuid */
  productionSharingContractId: string | null
  productionSharingMeteringPointType: ProductionSharingMeteringPointType
  meteringPointPowerSuppliers: ClientMeteringPointPowerSupplierDto[] | null
}

export interface ClientMeteringPointPowerSupplierDto {
  /** @format uuid */
  id: string
  powerSupplierId: string | null
  powerSupplierName: string | null
  /** @format date-time */
  startDate: string
  /** @format date-time */
  endDate: string | null
  isObligatedDelivery: boolean
}

export interface CodeAndNameDto {
  code: string | null
  displayName: string | null
}

export interface CompensationDto {
  /** @minLength 1 */
  id: string
  months: MonthCompensationDto[]
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export interface CompensationUnitDto {
  total: string | null
  priceThreshold: string | null
  maxConsumption: string | null
  averageSpotPrice: AverageSpotPriceUnitDto | null
}

export interface CompensationV2Dto {
  /** @format double */
  vat: number
  /** @format double */
  compensationDegree: number
  /** @format double */
  total: number
  averageSpotPrice: SpotPriceValueDto | null
  /** @format double */
  priceThreshold: number
  /** @format double */
  maxConsumption: number
}

export interface ConsumptionDto {
  /** @format double */
  value: number | null
  isVerified: boolean
  status: Status
}

export enum ConsumptionFormat {
  Json = 'Json',
  Xlsx = 'Xlsx',
}

export interface ConsumptionUnitDto {
  value: string | null
}

export interface ConsumptionValueDto {
  /** @format double */
  value: number | null
  isVerified: boolean
  status: Status
  unitOfMeasure: string | null
}

export interface ContractAccessInfoDto {
  /** @format uuid */
  contractId: string
  /** @format date-time */
  startTime: string
  /** @format date-time */
  endTime: string | null
  meteringPointId: string | null
}

export enum ContractStatus {
  Ordered = 'Ordered',
  UnderProcessing = 'UnderProcessing',
  Confirmed = 'Confirmed',
  Failed = 'Failed',
  Deleted = 'Deleted',
  Other = 'Other',
}

export interface ContractWithEarthFaultDto {
  /** @format uuid */
  contractId: string
  meteringPointId: string | null
  earthFaultTicket: EarthFaultTicketDto | null
}

export interface ContributorMeteringPointDto {
  meteringPointId: string | null
}

export interface CostComparisonDto {
  /** @minLength 1 */
  id: string
  consumption: ConsumptionDto
  originalCost: CostWithDistributionDto
  comparedCost: CostWithDistributionDto
  /** @format double */
  sumDifference: number
  /** @format double */
  sumDifferenceExTaxes: number
  /** @format double */
  sumDifferenceExVat: number
}

export interface CostComparisonsDto {
  comparisons: CostComparisonDto[]
  /** @format double */
  sumDifference: number
  /** @format double */
  sumDifferenceExTaxes: number
  /** @format double */
  sumDifferenceExVat: number
  originalPriceInfo: SinglePriceInfoDto
  comparedPriceInfo: SinglePriceInfoDto
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export interface CostDto {
  energy: PriceDto | null
  fixed: PriceDto | null
  sum: PriceDto | null
}

export interface CostUnitDto {
  total: string | null
  totalExVat: string | null
  totalExTaxes: string | null
}

export interface CostWithDistributionDto {
  energyPriceCost: PriceWithUnitDto
  fixedPriceCost: PriceWithUnitDto
  /** @format double */
  total: number
  /** @format double */
  totalExVat: number
  /** @format double */
  totalExTaxes: number
  distribution: DistributionDto
  maxHours: MaxHoursDto
  fixedPriceLevel: FixedPriceLevelDto
}

export interface CreateSharingTokenAccessDto {
  /** @minLength 1 */
  orgNumber: string
  /** @minLength 1 */
  orgName: string
  contractIds: string[]
}

export interface CreateTokenAccessDto {
  /** @minLength 1 */
  name: string
  contractIds: string[]
}

export interface CustomerAccessInfoDto {
  /** @format uuid */
  customerId: string
  role: Role
  /** @format date-time */
  lastAccessedDateUtc: string | null
  contracts: ContractAccessInfoDto[] | null
}

export enum CustomerType {
  Person = 'Person',
  Organization = 'Organization',
}

export interface DayDto {
  /** @minLength 1 */
  id: string
  production: ProductionDto | null
  consumption: ConsumptionDto | null
  cost: CostDto | null
  distribution: DistributionDto | null
  isPeriodCompleted: boolean
  hours: HourDto[]
  isWeekendOrHoliday: boolean
  /** @format double */
  compensationTotal: number | null
  powerSupplierCost: PriceDto | null
  spotPrice: SpotPriceValueDto | null
}

export interface DayMeterValueCostDto {
  /** @minLength 1 */
  id: string
  hours: HourMeterValueCostDto[]
  cost: CostDto | null
  consumption: ConsumptionDto | null
  isWeekendOrHoliday: boolean
}

export interface DayMeterValueDto {
  hours: HourMeterValueDto[]
  isWeekendOrHoliday: boolean
  /** @minLength 1 */
  id: string
  production: ProductionDto
  consumption: ConsumptionDto
}

export interface DayMeterValueMaxHourDto {
  id: string | null
  hour: HourMeterValueMaxHourDto | null
}

export interface DayMeterValuePriceDistributionDto {
  id: string | null
  distribution: DistributionDto | null
  isPeriodCompleted: boolean
}

export interface DaySpotPriceDto {
  /** @minLength 1 */
  id: string
  hours: HourSpotPriceDto[]
  spotPrice: SpotPriceDto
}

export interface DayTemperatureDto {
  /** @minLength 1 */
  id: string
  hours: HourTemperatureDto[]
  temperature: TemperatureDto
}

export interface DelegationTokenDto {
  access_token: string | null
  /** @format int32 */
  expires_in: number
  token_type: string | null
  scope: string | null
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export interface Deltagermaalepunkt {
  maalepunktId: string | null
  /** @format double */
  fordelingsNokkel: number | null
}

export interface DistributionDto {
  totalMeterValueAveragePriceDistribution: PriceWithUnitDto | null
  day: DistributionPriceDto | null
  night: DistributionPriceDto | null
}

export interface DistributionPriceDto {
  pricePointName: string | null
  price: PriceWithUnitDto | null
  /** @format double */
  percentage: number | null
  /** @format double */
  consumption: number | null
  consumptionUnitOfMeasure: string | null
}

export enum DistributionType {
  Equal = 'Equal',
  Manual = 'Manual',
  Other = 'Other',
}

export interface DistributionUnitDto {
  total: string | null
  totalExVat: string | null
  totalExTaxes: string | null
}

export interface DoubleValueDto {
  /** @format double */
  value: number
  unitOfMeasure: string | null
}

export interface EarthFaultCauseTypesDto {
  label: string | null
  placeholder: string | null
  items: CodeAndNameDto[] | null
}

export interface EarthFaultConnectionTypesDto {
  label: string | null
  placeholder: string | null
  items: CodeAndNameDto[] | null
}

export interface EarthFaultEventDto {
  /** @minLength 1 */
  meteringPointId: string
  items: EarthFaultEventItemDto[] | null
}

export interface EarthFaultEventItemDto {
  /** @format date-time */
  incidentTimestamp: string
  isEarthFaultDetected: boolean
  /** @format double */
  momentaryEarthFaultCurrentA: number
  /** @format double */
  momentaryEarthFaultVoltageV: number
}

export interface EarthFaultRectifyNoticeDeadlineDto {
  /** @format date-time */
  deadLine: string
}

export interface EarthFaultRectifyNoticeDto {
  connectionTypeCode: string | null
  causeTypeCode: string | null
  roomTypeCode: string | null
}

export interface EarthFaultRoomTypesDto {
  label: string | null
  placeholder: string | null
  items: CodeAndNameDto[] | null
}

export interface EarthFaultStatisticDto {
  /** @minLength 1 */
  meteringPointId: string
  items: EarthFaultStatisticItemDto[] | null
}

export interface EarthFaultStatisticItemDto {
  /** @format date-time */
  measurementTime: string
  /** @format date-time */
  maxCurrentTimestamp: string
  /** @format double */
  maxCurrentValue: number
}

export interface EarthFaultTicketDto {
  /** @format uuid */
  id: string
  /** @minLength 1 */
  meteringPointId: string
  /** @format int32 */
  ticketId: number
  /** @minLength 1 */
  salesforceCaseId: string
  /** @minLength 1 */
  cubitCaseNumber: string
  /** @minLength 1 */
  cubitPinCode: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
  earthFaultTicketStatus: EarthFaultTicketStatus
  /** @format date-time */
  rectifyNoticeDeadline: string | null
  /** @format date-time */
  latestRectifyNoticeDeadLine: string | null
}

export enum EarthFaultTicketStatus {
  NotifyCustomer = 'NotifyCustomer',
  CustomerNotified = 'CustomerNotified',
  RemindCustomer = 'RemindCustomer',
  CustomerReminded = 'CustomerReminded',
  ManualHandling = 'ManualHandling',
  RectifyNoticeReceived = 'RectifyNoticeReceived',
  VerifiedCorrected = 'VerifiedCorrected',
  Closed = 'Closed',
  Unknown = 'Unknown',
}

export interface EligibleProductionSharingMeteringPointDto {
  meteringPointId: string | null
  type: ProductionSharingMeteringPointType | null
  meteringPointType: MeteringPointType | null
  meterSerialNumber: string | null
  addressStreetName: string | null
  addressZipPlace: string | null
  addressFloor: string | null
  addressBuildingNumber: string | null
  addressLetter: string | null
  addressApartmentNumber: string | null
  addressZipCode: string | null
}

export interface EnergyPriceDto {
  /** @format uuid */
  id: string
  /** @format date-time */
  startDate: string
  /** @format date-time */
  endDate: string
  level: Level
  /** @format double */
  total: number
  /** @format double */
  energyExTaxes: number
  /** @format double */
  totalExVat: number
  /** @format double */
  taxes: number
  currency: string | null
  monetaryUnitOfMeasure: string | null
  isActive: boolean | null
}

export interface EnrolledOrganizationDto {
  /** @minLength 1 */
  orgNumber: string
  /** @minLength 1 */
  name: string
}

export interface FixedPriceDto {
  /** @format uuid */
  id: string
  /** @format date-time */
  startDate: string
  /** @format date-time */
  endDate: string
  priceLevels: FixedPriceLevelDto[] | null
}

export interface FixedPriceLevelDto {
  /** @format uuid */
  id: string
  /** @format int32 */
  valueMin: number | null
  /** @format int32 */
  valueMax: number | null
  /** @format uuid */
  nextIdDown: string | null
  /** @format uuid */
  nextIdUp: string | null
  valueUnitOfMeasure: string | null
  /** @format double */
  monthlyTotal: number
  /** @format double */
  monthlyTotalExVat: number
  /** @format double */
  monthlyExTaxes: number
  /** @format double */
  monthlyTaxes: number
  monthlyUnitOfMeasure: string | null
  levelInfo: string | null
  currency: string | null
  monetaryUnitOfMeasure: string | null
  /** @format int32 */
  level: number | null
  isActive: boolean
}

export interface FixedPriceLevelUnitDto {
  valueMin: string | null
  valueMax: string | null
  monthlyTotal: string | null
  monthlyTotalExVat: string | null
  monthlyExTaxes: string | null
  monthlyTaxes: string | null
}

export enum Fordelingstype {
  LikFordeling = 'LikFordeling',
  ManuelleAndeler = 'ManuelleAndeler',
}

export interface FormDataCreateDto {
  /** @minLength 1 */
  formType: string
  /** @minLength 1 */
  source: string
  /** @minLength 1 */
  subjectToCustomer: string
  /** @minLength 1 */
  subjectToCustomerService: string
  /** @minLength 1 */
  emailOfSender: string
  emailsOfReceivers: string[]
  /** @minLength 1 */
  htmlContent: string
  data: Record<string, string | null>
  jsonData: any
  customerCareReceivers: string[] | null
  recaptchaToken: string | null
}

export interface FormDataGetDto {
  /** @format uuid */
  id: string
  /** @format date-time */
  timeStamp: string
  formType: string | null
  source: string | null
  emailOfSender: string | null
  subjectToCustomerService: string | null
  subjectToCustomer: string | null
  attachmentData: string | null
  attachedFiles: string[] | null
  data: Record<string, string | null>
  jsonData: any
  customerCareReceivers: string[] | null
}

export interface HanPortEventBatchIncomingDto {
  contractIds: string[]
  status: HanPortStatus
  mobileNumberForNotification: string | null
}

export interface HanPortEventOutgoingDto {
  /** @format uuid */
  contractId: string
  /** @format date-time */
  createdDate: string
  status: HanPortStatus
  mobileNumberForNotification: string | null
}

export enum HanPortStatus {
  OpeningRequested = 'OpeningRequested',
  Open = 'Open',
  ClosingRequested = 'ClosingRequested',
  Closed = 'Closed',
  Failed = 'Failed',
  Unknown = 'Unknown',
}

export interface HistoricManualReadingDto {
  /** @minLength 1 */
  customerId: string
  /** @minLength 1 */
  contractId: string
  /** @minLength 1 */
  meteringPointId: string
  /** @format date-time */
  registrationTime: string
  meterSerialNumber: string | null
  /** @format double */
  meterValue: number
  customerType: CustomerType
}

export interface HourDto {
  /** @minLength 1 */
  id: string
  production: ProductionDto | null
  consumption: ConsumptionDto | null
  cost: CostDto | null
  level: Level
  /** @format double */
  compensationTotal: number | null
  powerSupplierCost: PriceDto | null
  spotPrice: SpotPriceValueDto | null
}

export interface HourMeterValueCostDto {
  /** @minLength 1 */
  id: string
  consumption: ConsumptionDto | null
  cost: CostDto | null
  level: Level
}

export interface HourMeterValueDto {
  /** @minLength 1 */
  id: string
  production: ProductionDto
  consumption: ConsumptionDto
  level: Level
}

export interface HourMeterValueMaxHourDto {
  id: string | null
  usedForFixedPriceLevelCalculation: boolean
  consumption: ConsumptionDto | null
}

export interface HourSpotPriceDto {
  /** @minLength 1 */
  id: string
  spotPrice: SpotPriceDto
}

export interface HourTemperatureDto {
  /** @minLength 1 */
  id: string
  temperature: TemperatureDto
}

export interface InvoiceDto {
  /** @minLength 1 */
  id: string
  /** @format date-time */
  invoiceDate: string
  /** @format date-time */
  from: string
  /** @format date-time */
  to: string
  /** @format double */
  total: number
  type: OriginEnum
  /** @minLength 1 */
  downloadUrl: string
  /** @format int32 */
  downloadUrlExpiryInSeconds: number
  state: PaymentState
  supplier: string | null
  /** @format uuid */
  customerId: string
  cabCustomerNumber: string | null
  elwinCustomerNumber: string | null
  stangeCustomerNumber: string | null
  customerReference: string | null
  customerGuid: string | null
  /** @format date-time */
  dueDate: string
  invoiceAgreementId: string | null
  paymentStateAfi: InvoiceStatusEnum | null
}

export enum InvoiceStatusEnum {
  Finished = 'Finished',
  Unfinished = 'Unfinished',
  WrittenOff = 'WrittenOff',
  NotApplicable = 'NotApplicable',
}

export enum Level {
  Unknown = 'Unknown',
  Cheap = 'Cheap',
  Expensive = 'Expensive',
}

export interface ManualReading {
  id: string | null
  /** @minLength 1 */
  contractId: string
  /** @minLength 1 */
  meteringPointId: string
  /** @format double */
  meterValue: number
  /** @format date-time */
  registrationTime: string
  createdBySubId: string | null
  createdByEmail: string | null
  customerType: CustomerType
  source: string | null
  meterSerialNumber: string | null
  /** @format uuid */
  customerId: string | null
}

export interface ManualReadingDto {
  /** @format double */
  meterValue: number
  customerType: CustomerType
}

export interface MaxHourDto {
  id: string | null
  /** @format double */
  consumption: number | null
  consumptionUnitOfMeasure: string | null
}

export interface MaxHoursDto {
  hours: MeterVolumeDto[]
  /** @format double */
  average: number
  consumptionUnitOfMeasure: string | null
}

export interface MeterValueCostDto {
  years: YearMeterValueCostDto[]
  unitOfMeasure: string | null
  currency: string | null
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export interface MeterValueDto {
  years: YearMeterValueDto[] | null
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export interface MeterValueMaxHourDto {
  years: YearMeterValueMaxHourDto[] | null
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export interface MeterValuePriceDistributionDto {
  yearMeterValuePriceDistribution: YearMeterValuePriceDistributionDto | null
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export interface MeterValueV2Dto {
  years: YearDto[] | null
  unit: UnitDto | null
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export interface MeterVolumeDto {
  /** @minLength 1 */
  id: string
  production: ProductionDto
  consumption: ConsumptionDto
}

export enum MeteringPointType {
  Production = 'Production',
  Consumption = 'Consumption',
  Combined = 'Combined',
  Unknown = 'Unknown',
}

export interface MonthCompensationDto {
  /** @minLength 1 */
  id: string
  /** @format double */
  vat: number
  /** @format double */
  compensationDegree: number
  priceThreshold: DoubleValueDto
  consumption: ConsumptionValueDto
  averageSpotPrice: AverageSpotPriceValueDto
  maxConsumption: DoubleValueDto
  isPeriodCompleted: boolean
}

export interface MonthDto {
  /** @minLength 1 */
  id: string
  production: ProductionDto | null
  consumption: ConsumptionDto | null
  cost: CostDto | null
  distribution: DistributionDto | null
  isPeriodCompleted: boolean
  days: DayDto[]
  maxHours: MaxHoursDto | null
  fixedPriceLevels: FixedPriceLevelDto[] | null
  /** @format double */
  compensationTotal: number | null
  compensation: CompensationV2Dto | null
  powerSupplierCost: PriceDto | null
  spotPrice: SpotPriceValueDto | null
}

export interface MonthMeterValueCostDto {
  /** @minLength 1 */
  id: string
  days: DayMeterValueCostDto[]
  cost: CostDto | null
  consumption: ConsumptionDto | null
}

export interface MonthMeterValueDto {
  days: DayMeterValueDto[]
  maxHourId: string | null
  maxHours: MaxHoursDto
  /** @minLength 1 */
  id: string
  production: ProductionDto
  consumption: ConsumptionDto
}

export interface MonthMeterValueMaxHourDto {
  id: string | null
  /** @format double */
  calculatedFixedPriceAverage: number
  consumptionUnitOfMeasure: string | null
  days: DayMeterValueMaxHourDto[] | null
  fixedPriceLevels: FixedPriceLevelDto[] | null
}

export interface MonthMeterValuePriceDistributionDto {
  id: string | null
  fixedPriceLevel: FixedPriceLevelDto | null
  distribution: DistributionDto | null
  days: DayMeterValuePriceDistributionDto[] | null
  maxHour: MaxHourDto | null
  isPeriodCompleted: boolean
}

export interface MonthSpotPriceDto {
  days: DaySpotPriceDto[]
  /** @minLength 1 */
  id: string
  spotPrice: SpotPriceDto
}

export interface MonthTemperatureDto {
  days: DayTemperatureDto[]
  /** @minLength 1 */
  id: string
  temperature: TemperatureDto
}

export interface NotificationPreferenceDto {
  email: string | null
  mobile: string | null
}

export enum NotificationSource {
  Elhub = 'Elhub',
  Kundesenter = 'Kundesenter',
  Migrated = 'Migrated',
  MinSide = 'MinSide',
  Other = 'Other',
}

export enum NotificationType {
  Elhub = 'Elhub',
  PowerOutage = 'PowerOutage',
  MeterReading = 'MeterReading',
  Other = 'Other',
}

export enum OriginEnum {
  Cab = 'Cab',
  Elwin = 'Elwin',
  Afi = 'Afi',
  Stange = 'Stange',
}

export interface ParticipantMeteringPointDto {
  meteringPointId: string | null
  /** @format double */
  distribution: number | null
}

export enum PaymentState {
  Unpaid = 'unpaid',
  Paid = 'paid',
  Billed = 'billed',
  Credited = 'credited',
  Osb = 'osb',
  Debtcollection = 'debtcollection',
  Unknown = 'unknown',
}

export enum Permission {
  BankaccountWrite = 'bankaccount.write',
  DelegationtokenWrite = 'delegationtoken.write',
  NotificationpreferencesWrite = 'notificationpreferences.write',
  ContractRead = 'contract.read',
  ProductionsharingcontractWrite = 'productionsharingcontract.write',
  ManualreadingWrite = 'manualreading.write',
  ProductionsharingcontractRead = 'productionsharingcontract.read',
  CustomeraccessRevokeGeneral = 'customeraccess.revoke.general',
  CustomeraccessRevokeOwner = 'customeraccess.revoke.owner',
  CustomeraccessRevokeSelf = 'customeraccess.revoke.self',
  CustomeraccessWrite = 'customeraccess.write',
}

export interface PriceDto {
  /** @format double */
  total: number | null
  /** @format double */
  totalExTaxes: number | null
  /** @format double */
  totalExVat: number | null
}

export interface PriceWithUnitDto {
  monetaryUnitOfMeasure: string | null
  currency: string | null
  /** @format double */
  total: number | null
  /** @format double */
  totalExTaxes: number | null
  /** @format double */
  totalExVat: number | null
}

export interface ProblemDetails {
  type: string | null
  title: string | null
  /** @format int32 */
  status: number | null
  detail: string | null
  instance: string | null
  [key: string]: any
}

export interface ProductPriceDto {
  /** @format uuid */
  productId: string
  productName: string | null
  fixedPrices: FixedPriceDto[] | null
  energyPrices: EnergyPriceDto[] | null
}

export interface ProductPricesDto {
  productPrices: ProductPriceDto[] | null
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export interface ProductionDto {
  /** @format double */
  value: number | null
  isVerified: boolean
  status: Status
}

export interface ProductionSharingContractDto {
  /** @format uuid */
  id: string
  /** @format int32 */
  version: number
  subId: string | null
  /** @format uuid */
  elhubId: string | null
  /** @format int32 */
  elhubVersion: number | null
  name: string | null
  description: string | null
  distributionType: DistributionType
  /** @format date-time */
  startTime: string
  /** @format date-time */
  endTime: string | null
  status: ContractStatus
  termsId: string | null
  termsRevision: string | null
  /** @format date-time */
  termsAcceptedDateUtc: string | null
  rejections: string[] | null
  ownerMeteringPointId: string | null
  productionSharingMeteringPoints: ProductionSharingMeteringPointDto[] | null
}

export interface ProductionSharingMeteringPointDto {
  meteringPointId: string | null
  /** @format double */
  distribution: number | null
  type: ProductionSharingMeteringPointType
  meterSerialNumber: string | null
  addressStreetName: string | null
  addressZipPlace: string | null
  addressFloor: string | null
  addressBuildingNumber: string | null
  addressLetter: string | null
  addressApartmentNumber: string | null
  addressZipCode: string | null
}

export enum ProductionSharingMeteringPointType {
  Contributor = 'Contributor',
  Participant = 'Participant',
  Unknown = 'Unknown',
}

export interface ProductionSharingOrderDto {
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string
  description: string | null
  /** @format date-time */
  startDate: string
  /** @format date-time */
  endDate: string | null
  distributionType: DistributionType
  ownerMeteringPointId: string | null
  contributorMeteringPoints: ContributorMeteringPointDto[]
  participantMeteringPoints: ParticipantMeteringPointDto[]
}

export interface ProductionSharingOrderMessage {
  /** @format uuid */
  elviaId: string
  /** @format int32 */
  elviaVersjon: number
  navn: string | null
  beskrivelse: string | null
  fordelingstype: Fordelingstype
  /** @format date */
  startdato: string
  /** @format date */
  sluttdato: string | null
  eier: Bidragsytermaalepunkt | null
  bidragsytermaalepunkt: Bidragsytermaalepunkt[] | null
  deltagermaalepunkt: Deltagermaalepunkt[] | null
}

export enum Role {
  Owner = 'Owner',
  CoOwner = 'CoOwner',
  CompanyReader = 'CompanyReader',
  MachineReader = 'MachineReader',
  CustomerService = 'CustomerService',
  MachineReaderOrganization = 'MachineReaderOrganization',
  CompanyOwner = 'CompanyOwner',
  CompanyAdmin = 'CompanyAdmin',
}

export interface SingleEnergyPriceDto {
  day: EnergyPriceDto | null
  night: EnergyPriceDto | null
}

export interface SinglePriceInfoDto {
  energyPrice: SingleEnergyPriceDto
  fixedPrice: FixedPriceDto | null
  /** @format date-time */
  calculateTime: string
  /** @format date-time */
  startDate: string | null
  /** @format date-time */
  endDate: string | null
}

export interface SpotPriceDto {
  average: SpotPriceValueDto
  max: SpotPriceValueDto
  min: SpotPriceValueDto
}

export interface SpotPriceValueDto {
  /** @format double */
  total: number
  /** @format double */
  totalExTaxes: number
  /** @format double */
  taxes: number
}

export interface SpotPricesDto {
  /** @minLength 1 */
  unitOfMeasure: string
  years: YearSpotPriceDto[]
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export enum Status {
  OK = 'OK',
  Est = 'Est',
}

export interface TemperatureDto {
  /** @format double */
  value: number
  /** @minLength 1 */
  unitOfMeasure: string
}

export interface UnitDto {
  consumption: ConsumptionUnitDto | null
  production: ConsumptionUnitDto | null
  cost: CostUnitDto | null
  distribution: DistributionUnitDto | null
  fixedPriceLevels: FixedPriceLevelUnitDto | null
  compensation: CompensationUnitDto | null
}

export interface UpdateAccessRoleRequest {
  updateToRole: Role
}

export interface UserDto {
  sentAccessRequests: ClientAccessRequestsBySmsOutgoingDto[] | null
  /** @format date-time */
  latestActivity: string | null
}

export interface ValidationProblemDetails {
  errors: Record<string, string[]>
  type: string | null
  title: string | null
  /** @format int32 */
  status: number | null
  detail: string | null
  instance: string | null
  [key: string]: any
}

export interface WeatherForecastDto {
  years: YearTemperatureDto[]
  /** @format uuid */
  customerId: string | null
  /** @format uuid */
  contractId: string | null
}

export interface YearDto {
  /** @minLength 1 */
  id: string
  production: ProductionDto | null
  consumption: ConsumptionDto | null
  cost: CostDto | null
  distribution: DistributionDto | null
  isPeriodCompleted: boolean
  months: MonthDto[]
  daylightSavingTimeStart: string | null
  daylightSavingTimeEnd: string | null
  powerSupplierCost: PriceDto | null
  /** @format double */
  compensationTotal: number | null
  spotPrice: SpotPriceValueDto | null
}

export interface YearMeterValueCostDto {
  /** @minLength 1 */
  id: string
  months: MonthMeterValueCostDto[]
  consumption: ConsumptionDto | null
  cost: CostDto | null
  daylightSavingTimeStart: string | null
  daylightSavingTimeEnd: string | null
}

export interface YearMeterValueDto {
  months: MonthMeterValueDto[]
  daylightSavingTimeStart: string | null
  daylightSavingTimeEnd: string | null
  maxHourId: string | null
  /** @minLength 1 */
  id: string
  production: ProductionDto
  consumption: ConsumptionDto
}

export interface YearMeterValueMaxHourDto {
  id: string | null
  months: MonthMeterValueMaxHourDto[] | null
}

export interface YearMeterValuePriceDistributionDto {
  id: string | null
  fixedPriceLevel: FixedPriceLevelDto | null
  distribution: DistributionDto | null
  months: MonthMeterValuePriceDistributionDto[] | null
  isPeriodCompleted: boolean
  maxHour: MaxHourDto | null
}

export interface YearSpotPriceDto {
  /** @minLength 1 */
  id: string
  months: MonthSpotPriceDto[]
  spotPrice: SpotPriceDto
}

export interface YearTemperatureDto {
  /** @minLength 1 */
  id: string
  months: MonthTemperatureDto[]
  temperature: TemperatureDto
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://kunde.test-elvia.io/portal',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title Kundeportal-api
 * @version 1
 * @baseUrl https://kunde.test-elvia.io/portal
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  customer = {
    /**
     * @description Get Accesses by CustomerId. Represented by CustomerAccess in database.
     *
     * @tags Access
     * @name AccessDetail
     * @summary Get Accesses by CustomerId
     * @request GET:/customer/{customerId}/access
     * @secure
     */
    accessDetail: (
      customerId: string,
      query?: {
        /** @default false */
        includeRevokedAccess?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<AccessesDto, ValidationProblemDetails>({
        path: `/customer/${customerId}/access`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a token with access to the customer. The token is long lived, and can be e.g. be used to get Metervalues from the API
     *
     * @tags Access
     * @name AccessCreate
     * @summary Create token access.
     * @request POST:/customer/{customerId}/access
     * @secure
     */
    accessCreate: (customerId: string, data: CreateTokenAccessDto, params: RequestParams = {}) =>
      this.request<DelegationTokenDto, ValidationProblemDetails>({
        path: `/customer/${customerId}/access`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update status of an access. Currently only revoke/ delete is supported. Revoke by setting {"status": "Revoked"}.
     *
     * @tags Access
     * @name AccessPartialUpdate
     * @summary Update access by revoke/ remove it.
     * @request PATCH:/customer/{customerId}/access/{accessId}
     * @secure
     */
    accessPartialUpdate: (customerId: string, accessId: string, data: AccessStatusDto, params: RequestParams = {}) =>
      this.request<AccessesDto, ValidationProblemDetails>({
        path: `/customer/${customerId}/access/${accessId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update the role of an customeraccess identified by accessId.
     *
     * @tags Access
     * @name AccessCreate2
     * @summary Update customeraccess role by accessId.
     * @request POST:/customer/{customerId}/access/{accessId}
     * @originalName accessCreate
     * @duplicate
     * @secure
     */
    accessCreate2: (customerId: string, accessId: string, data: UpdateAccessRoleRequest, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/customer/${customerId}/access/${accessId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Access
     * @name SharingAccessCreate
     * @summary Creates an access to the customer's contract (metering point) for an external organization
     * @request POST:/customer/{customerId}/sharingAccess
     * @secure
     */
    sharingAccessCreate: (customerId: string, data: CreateSharingTokenAccessDto, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/customer/${customerId}/sharingAccess`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get AccessRequests by CustomerId.
     *
     * @tags AccessRequest
     * @name AccessrequestDetail
     * @summary Get AccessRequests by CustomerId
     * @request GET:/customer/{customerId}/accessrequest
     * @secure
     */
    accessrequestDetail: (customerId: string, params: RequestParams = {}) =>
      this.request<AccessRequestsOutgoingDto[], ValidationProblemDetails>({
        path: `/customer/${customerId}/accessrequest`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccessRequest
     * @name AccessrequestOrganizationRevokedDetail
     * @summary Get revoked organization access requests by customerId
     * @request GET:/customer/{customerId}/accessrequest/organization/revoked
     * @secure
     */
    accessrequestOrganizationRevokedDetail: (customerId: string, params: RequestParams = {}) =>
      this.request<AccessRequestsRevokedOutgoingDto[], ValidationProblemDetails>({
        path: `/customer/${customerId}/accessrequest/organization/revoked`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compensation
     * @name ContractCompensationDetail
     * @summary Get compensation for a given year.
     * @request GET:/customer/{customerId}/contract/{contractId}/compensation
     * @secure
     */
    contractCompensationDetail: (
      customerId: string,
      contractId: string,
      query?: {
        /**
         * example: 2019, 2020, 2021, default is current year
         * @format int32
         */
        year?: number
        /**
         * Include unverified metervalues
         * @default false
         */
        includeUnverifiedValues?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<CompensationDto, ValidationProblemDetails>({
        path: `/customer/${customerId}/contract/${contractId}/compensation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows client to show a list of all contracts that the user has access to for a given customerId
     *
     * @tags Contract
     * @name ContractDetail
     * @summary Get a list of contracts for a specific customerId
     * @request GET:/customer/{customerId}/contract
     * @secure
     */
    contractDetail: (
      customerId: string,
      query?: {
        /** @format int32 */
        PageNumber?: number
        /** @format int32 */
        PageSize?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ClientContractDto[], ValidationProblemDetails>({
        path: `/customer/${customerId}/contract`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows client to show a list of Person customers that the user has access to
     *
     * @tags Customer
     * @name CustomerList
     * @summary Get a list of Person customers that the user has access to
     * @request GET:/customer
     * @secure
     */
    customerList: (
      query?: {
        /** @format int32 */
        PageNumber?: number
        /** @format int32 */
        PageSize?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ClientCustomerDto[], ValidationProblemDetails>({
        path: `/customer`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows client to determine which customer is logged in.
     *
     * @tags Customer
     * @name GetCustomer
     * @summary Get the customers where the loggged in user is owner, i.e., get customer-object of yourself.
     * @request GET:/customer/me
     * @secure
     */
    getCustomer: (
      query?: {
        /** @format int32 */
        PageNumber?: number
        /** @format int32 */
        PageSize?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ClientCustomerDto[], ValidationProblemDetails>({
        path: `/customer/me`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows client to show a customer that the user has access to
     *
     * @tags Customer
     * @name CustomerDetail
     * @summary Get a Person or Organization customer that the user has access to
     * @request GET:/customer/{customerId}
     * @secure
     */
    customerDetail: (
      customerId: string,
      query?: {
        /** @format int32 */
        PageNumber?: number
        /** @format int32 */
        PageSize?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ClientCustomerDto, ValidationProblemDetails>({
        path: `/customer/${customerId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EarthFault
     * @name ContractsEarthfaultDetail
     * @summary Get a list of contracts with earth fault tickets
     * @request GET:/customer/{customerId}/contracts/earthfault
     * @secure
     */
    contractsEarthfaultDetail: (customerId: string, params: RequestParams = {}) =>
      this.request<ContractWithEarthFaultDto[], ValidationProblemDetails>({
        path: `/customer/${customerId}/contracts/earthfault`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows client to show a list of invoices for a given contract. </br> The following payment statuses are available: paid = betalt unpaid = ubetalt billed = sendt til strømleverandør </br> Append includeDetailedPaymentStatus=true, for detailed payment statuses: credited = kreditert osb = overføres til neste faktura debtcollection = sendt til inkasso
     *
     * @tags Invoice
     * @name ContractInvoiceDetail
     * @summary Get a list of invoices for a given contract
     * @request GET:/customer/{customerId}/contract/{contractId}/invoice
     * @secure
     */
    contractInvoiceDetail: (
      customerId: string,
      contractId: string,
      query?: {
        /**
         * includes the following payment statuses: credited, osb, and debtcollection
         * @default false
         */
        includeDetailedPaymentStatus?: boolean
        /**
         * generates unique downloadUrl with token
         * @default true
         */
        generateTokenUrl?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceDto[], ValidationProblemDetails>({
        path: `/customer/${customerId}/contract/${contractId}/invoice`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ManualReading
     * @name ContractManualReadingDetail
     * @summary Get latest specified number of manual readings by customer id and contract id
     * @request GET:/customer/{customerId}/contract/{contractId}/manualReading
     * @secure
     */
    contractManualReadingDetail: (
      customerId: string,
      contractId: string,
      query?: {
        /** @format int32 */
        numberOfReadings?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ManualReading[], ProblemDetails | ValidationProblemDetails>({
        path: `/customer/${customerId}/contract/${contractId}/manualReading`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ManualReading
     * @name ContractManualReadingCreate
     * @summary Add latest manual reading for customer id and contract id
     * @request POST:/customer/{customerId}/contract/{contractId}/manualReading
     * @secure
     */
    contractManualReadingCreate: (
      customerId: string,
      contractId: string,
      data: ManualReadingDto,
      params: RequestParams = {}
    ) =>
      this.request<ManualReading, ProblemDetails | ValidationProblemDetails>({
        path: `/customer/${customerId}/contract/${contractId}/manualReading`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MeterValue
     * @name ContractMetervalueDetail
     * @summary Get metervalues for a contract for a given timespan.
     * @request GET:/customer/{customerId}/contract/{contractId}/metervalue
     * @secure
     */
    contractMetervalueDetail: (
      customerId: string,
      contractId: string,
      query?: {
        /**
         * example: 2019, 2020, 2021, default is current year
         * @format int32
         */
        year?: number
        /**
         * Include unverified metervalues
         * @default false
         */
        includeUnverifiedValues?: boolean
        /**
         * Include empty values until end of next day.
         * @default false
         */
        includeEmptyValues?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<MeterValueDto, ValidationProblemDetails>({
        path: `/customer/${customerId}/contract/${contractId}/metervalue`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MeterValue
     * @name ContractMetervalueFileDownloadDetail
     * @summary Get file of metervalues on contract for a given timespan as json or xlsx.
     * @request GET:/customer/{customerId}/contract/{contractId}/metervalue/file/download
     * @secure
     */
    contractMetervalueFileDownloadDetail: (
      customerId: string,
      contractId: string,
      query: {
        /**
         * From date example: 2019-05-10T12:53:09.396Z
         * @format date-time
         */
        from: string
        /**
         * To date example: 2019-05-10T12:53:09.396Z
         * @format date-time
         */
        to: string
        /** Format to download file, either json or xlsx. Required. */
        fileFormat: ConsumptionFormat
        /** Include year values in file. Default true. */
        IncludeYear?: boolean
        /** Include month values in file. Default true. */
        IncludeMonth?: boolean
        /** Include week values in file. Default true. */
        IncludeWeek?: boolean
        /** Include day values in file. Default true. */
        IncludeDay?: boolean
        /** Include hour values in file. Default true. */
        IncludeHour?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/customer/${customerId}/contract/${contractId}/metervalue/file/download`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MeterValue
     * @name ContractMetervaluePricedistributionDetail
     * @summary Get metervalue price distribution for a contract for a given year.
     * @request GET:/customer/{customerId}/contract/{contractId}/metervalue/pricedistribution
     * @secure
     */
    contractMetervaluePricedistributionDetail: (
      customerId: string,
      contractId: string,
      query?: {
        /**
         * example: 2019, 2020, 2021, default is current year
         * @format int32
         */
        year?: number
        /**
         * Include unverified metervalues
         * @default false
         */
        includeUnverifiedValues?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<MeterValuePriceDistributionDto, ValidationProblemDetails>({
        path: `/customer/${customerId}/contract/${contractId}/metervalue/pricedistribution`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MeterValue
     * @name ContractMetervalueMaxhourDetail
     * @summary Get metervalue max hours for a contract for a given year.
     * @request GET:/customer/{customerId}/contract/{contractId}/metervalue/maxhour
     * @secure
     */
    contractMetervalueMaxhourDetail: (
      customerId: string,
      contractId: string,
      query?: {
        /**
         * example: 2019, 2020, 2021, default is current year
         * @format int32
         */
        year?: number
        /**
         * Include unverified metervalues
         * @default false
         */
        includeUnverifiedValues?: boolean
        /**
         * Use standard_for_tariffkalkulator to override actual prices before 1. July.
         * @default false
         */
        overridePricesWithTariffCalculator?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<MeterValueMaxHourDto, ValidationProblemDetails>({
        path: `/customer/${customerId}/contract/${contractId}/metervalue/maxhour`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MeterValue
     * @name ContractMetervalueCostDetail
     * @summary Get total cost for a contract for a given year.
     * @request GET:/customer/{customerId}/contract/{contractId}/metervalue/cost
     * @secure
     */
    contractMetervalueCostDetail: (
      customerId: string,
      contractId: string,
      query?: {
        /**
         * example: 2019, 2020, 2021, default is current year
         * @format int32
         */
        year?: number
        /**
         * Include unverified metervalues
         * @default false
         */
        includeUnverifiedValues?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<MeterValueCostDto, ValidationProblemDetails>({
        path: `/customer/${customerId}/contract/${contractId}/metervalue/cost`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductionSharing
     * @name ProductionsharingcontractsDetail
     * @summary Get all production sharing contracts for customer
     * @request GET:/customer/{customerId}/productionsharingcontracts
     * @secure
     */
    productionsharingcontractsDetail: (customerId: string, params: RequestParams = {}) =>
      this.request<ProductionSharingContractDto[], ValidationProblemDetails>({
        path: `/customer/${customerId}/productionsharingcontracts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  access = {
    /**
     * @description Get contracts with MeteringPointId and from/to date of access connected to the token. Dependent on scope, the token from Authorization header or Delegation-Token header will be inspected.
     *
     * @tags Access
     * @name DelegationList
     * @summary Get accesses from delegation token.
     * @request GET:/access/delegation
     * @secure
     */
    delegationList: (params: RequestParams = {}) =>
      this.request<AccessInfoDto, ValidationProblemDetails>({
        path: `/access/delegation`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  accessrequest = {
    /**
     * @description Get AccessRequest by Id.
     *
     * @tags AccessRequest
     * @name AccessrequestDetail
     * @summary Get AccessRequest by Id
     * @request GET:/accessrequest/{id}
     * @secure
     */
    accessrequestDetail: (id: string, params: RequestParams = {}) =>
      this.request<ClientAccessRequestBySmsOutgoingDto, ValidationProblemDetails>({
        path: `/accessrequest/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update status of an access request. Approved = 1/Rejected = 2/Cancelled = 3/Revoked = 4
     *
     * @tags AccessRequest
     * @name AccessrequestPartialUpdate
     * @summary Update status of an access request. Approved/Rejected/Cancelled/Revoked.
     * @request PATCH:/accessrequest/{id}
     * @secure
     */
    accessrequestPartialUpdate: (id: string, data: AccessRequestStatusDto, params: RequestParams = {}) =>
      this.request<void, void | ValidationProblemDetails>({
        path: `/accessrequest/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Request an access to another customer, either with CustomerNumber and MeterSerialNumber (for an organization), or with MobileNumberReceiver (for a person).
     *
     * @tags AccessRequest
     * @name AccessrequestCreate
     * @summary Request an access to another customer
     * @request POST:/accessrequest
     * @secure
     */
    accessrequestCreate: (data: AccessRequestIncomingDto, params: RequestParams = {}) =>
      this.request<AccessRequestOutgoingDto, AccessRequestErrorDto | ProblemDetails | ValidationProblemDetails>({
        path: `/accessrequest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  me = {
    /**
     * @description Get all AccessRequests submitted by the user, grouped by a combination of sender's name, subIdCustomerSendingRequest and mobileNumberSender. Used by web and app.
     *
     * @tags AccessRequest
     * @name AccessrequestSentList
     * @summary Get all AccessRequests submitted by the user
     * @request GET:/me/accessrequest/sent
     * @secure
     */
    accessrequestSentList: (params: RequestParams = {}) =>
      this.request<ClientAccessRequestsBySmsOutgoingDto[], ValidationProblemDetails>({
        path: `/me/accessrequest/sent`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Request an access to another customer.
     *
     * @tags AccessRequest
     * @name AccessrequestActionRequestcustomeraccessbysmsCreate
     * @summary Request an access to another customer
     * @request POST:/me/accessrequest/action/requestcustomeraccessbysms
     * @secure
     */
    accessrequestActionRequestcustomeraccessbysmsCreate: (
      data: ClientAccessRequestBySmsIncomingDto,
      params: RequestParams = {}
    ) =>
      this.request<ClientAccessRequestBySmsOutgoingDto, ValidationProblemDetails>({
        path: `/me/accessrequest/action/requestcustomeraccessbysms`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a cancelled/rejected access request. Used by app.
     *
     * @tags AccessRequest
     * @name AccessrequestActionDeleteCreate
     * @summary Delete a cancelled/rejected access request
     * @request POST:/me/accessrequest/{id}/action/delete
     * @secure
     */
    accessrequestActionDeleteCreate: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | void>({
        path: `/me/accessrequest/${id}/action/delete`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Cancel a pending access request. Used by app.
     *
     * @tags AccessRequest
     * @name AccessrequestActionCancelCreate
     * @summary Cancel a pending access request
     * @request POST:/me/accessrequest/{id}/action/cancel
     * @secure
     */
    accessrequestActionCancelCreate: (id: string, params: RequestParams = {}) =>
      this.request<ClientAccessRequestBySmsOutgoingDto, ValidationProblemDetails>({
        path: `/me/accessrequest/${id}/action/cancel`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Reject an access request.
     *
     * @tags AccessRequest
     * @name AccessrequestActionRejectCreate
     * @summary Reject an access request
     * @request POST:/me/accessrequest/{id}/action/reject
     * @secure
     */
    accessrequestActionRejectCreate: (id: string, params: RequestParams = {}) =>
      this.request<ClientAccessRequestBySmsOutgoingDto, ValidationProblemDetails | ProblemDetails>({
        path: `/me/accessrequest/${id}/action/reject`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  contract = {
    /**
     * @description Post bankAccount number linked to contract
     *
     * @tags BankAccount
     * @name BankaccountnumberCreate
     * @summary Post bankAccount number linked to contract
     * @request POST:/contract/{contractId}/bankaccountnumber
     * @secure
     */
    bankaccountnumberCreate: (contractId: string, data: BankAccountNumberDto, params: RequestParams = {}) =>
      this.request<BankAccountNumberDto, ValidationProblemDetails>({
        path: `/contract/${contractId}/bankaccountnumber`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows client to show a list of all earth fault tickets that the user has access to for a given contract id
     *
     * @tags EarthFault
     * @name EarthfaultTicketsDetail
     * @summary Get a list of earth fault tickets
     * @request GET:/contract/{contractId}/earthfault/tickets
     * @secure
     */
    earthfaultTicketsDetail: (contractId: string, params: RequestParams = {}) =>
      this.request<EarthFaultTicketDto[], ValidationProblemDetails>({
        path: `/contract/${contractId}/earthfault/tickets`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows client to post a rectifyNotice deadline to the earthFault API
     *
     * @tags EarthFault
     * @name EarthfaultRectifynoticedeadlineCreate
     * @summary Post an earth fault rectify notice deadline
     * @request POST:/contract/{contractId}/earthfault/{earthFaultTicketId}/rectifynoticedeadline
     * @secure
     */
    earthfaultRectifynoticedeadlineCreate: (
      contractId: string,
      earthFaultTicketId: string,
      data: EarthFaultRectifyNoticeDeadlineDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/contract/${contractId}/earthfault/${earthFaultTicketId}/rectifynoticedeadline`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Allows client to post a rectifyNotice to the earthFault API
     *
     * @tags EarthFault
     * @name EarthfaultRectifynoticeCreate
     * @summary Post an earth fault rectify notice
     * @request POST:/contract/{contractId}/earthfault/{earthFaultTicketId}/rectifynotice
     * @secure
     */
    earthfaultRectifynoticeCreate: (
      contractId: string,
      earthFaultTicketId: string,
      data: EarthFaultRectifyNoticeDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/contract/${contractId}/earthfault/${earthFaultTicketId}/rectifynotice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Allows client to show a list of all earth fault events that the user has access to for a given contract id <param name="incidentTimestampGt"> </br>incidentTimestampGt (GreaterThan) example: 2024-01-23T12:15:09.396Z</param><param name="incidentTimestampLt"> </br>incidentTimestampLt (LessThan) example: 2024-01-23T12:15:09.396Z</param><param name="includeStatistics"> </br>includeStatistics with value true, includes statistics as events</param>
     *
     * @tags EarthFault
     * @name EarthfaultEventsDetail
     * @summary Get a list of earth fault events
     * @request GET:/contract/{contractId}/earthfault/{earthFaultTicketId}/events
     * @secure
     */
    earthfaultEventsDetail: (
      contractId: string,
      earthFaultTicketId: string,
      query?: {
        /** @format date-time */
        incidentTimestampGt?: string
        /** @format date-time */
        incidentTimestampLt?: string
        /** @default false */
        includeStatistics?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<EarthFaultEventDto, ValidationProblemDetails>({
        path: `/contract/${contractId}/earthfault/${earthFaultTicketId}/events`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows client to show a list of all earth fault statistics that the user has access to for a given contract id <param name="measurementTimeGt"> </br>measurementTimeGt (GreaterThan) example: 2024-01-23T12:15:09.396Z</param><param name="measurementTimeLt"> </br>measurementTimeLt (LessThan) example: 2024-01-23T12:15:09.396Z</param>
     *
     * @tags EarthFault
     * @name EarthfaultStatisticsDetail
     * @summary Get a list of earth fault statistics
     * @request GET:/contract/{contractId}/earthfault/{earthFaultTicketId}/statistics
     * @secure
     */
    earthfaultStatisticsDetail: (
      contractId: string,
      earthFaultTicketId: string,
      query?: {
        /** @format date-time */
        measurementTimeGt?: string
        /** @format date-time */
        measurementTimeLt?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<EarthFaultStatisticDto, ValidationProblemDetails>({
        path: `/contract/${contractId}/earthfault/${earthFaultTicketId}/statistics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a notification preference on a contract in salesforce
     *
     * @tags NotificationPreference
     * @name NotificationpreferenceUpdate
     * @summary Update a notification preference
     * @request PUT:/contract/{contractId}/notificationpreference/{notificationPreferenceId}
     * @secure
     */
    notificationpreferenceUpdate: (
      contractId: string,
      notificationPreferenceId: string,
      data: NotificationPreferenceDto,
      params: RequestParams = {}
    ) =>
      this.request<ProductionSharingOrderMessage, ValidationProblemDetails>({
        path: `/contract/${contractId}/notificationpreference/${notificationPreferenceId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Price valid as of startDate Price valid until endDate
     *
     * @tags Product
     * @name ProductDetail
     * @summary Get product linked to contract
     * @request GET:/contract/{contractId}/product
     * @secure
     */
    productDetail: (contractId: string, params: RequestParams = {}) =>
      this.request<ProductPricesDto, any>({
        path: `/contract/${contractId}/product`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Price valid as of startDate Price valid until endDate
     *
     * @tags Product
     * @name ProductPricesDetail
     * @summary Get prices for a contract at a particular time
     * @request GET:/contract/{contractId}/product/prices
     * @secure
     */
    productPricesDetail: (
      contractId: string,
      query?: {
        /**
         * DateTime used to get prices at a particular time. Default is current time
         * @format date-time
         */
        calculateTime?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SinglePriceInfoDto, any>({
        path: `/contract/${contractId}/product/prices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductCostComparisonDetail
     * @summary Get product cost comparison between current tariff and new 2022 tariff, based on metervalues.
     * @request GET:/contract/{contractId}/product/cost/comparison
     * @secure
     */
    productCostComparisonDetail: (
      contractId: string,
      query: {
        /**
         * From value. Will use the UTC month value. Example: "2022-07-01Z" will use month 7 (july)
         * @format date-time
         */
        from: string
        /**
         * Optional to value. Will use the UTC month value. Default is UtcNow.
         * @format date-time
         */
        to?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<CostComparisonsDto, ValidationProblemDetails>({
        path: `/contract/${contractId}/product/cost/comparison`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductionSharing
     * @name ProductionsharingcontractDetail
     * @summary Get production sharing contract by contract id (through its metering point)
     * @request GET:/contract/{contractId}/productionsharingcontract
     * @secure
     */
    productionsharingcontractDetail: (contractId: string, params: RequestParams = {}) =>
      this.request<ProductionSharingContractDto, ValidationProblemDetails>({
        path: `/contract/${contractId}/productionsharingcontract`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductionSharing
     * @name ProductionsharingcontractEligiblemeteringpointsDetail
     * @summary Get eligible metering points for a production sharing contract by contract id (through its metering point)
     * @request GET:/contract/{contractId}/productionsharingcontract/eligiblemeteringpoints
     * @secure
     */
    productionsharingcontractEligiblemeteringpointsDetail: (
      contractId: string,
      query?: {
        /**
         * Production sharing contract id if exists (e.g. in edit mode)
         * @format uuid
         */
        productionSharingContractId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<EligibleProductionSharingMeteringPointDto[], ValidationProblemDetails>({
        path: `/contract/${contractId}/productionsharingcontract/eligiblemeteringpoints`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SpotPrice
     * @name SpotpriceDetail
     * @summary Get spot price from Nord pool
     * @request GET:/contract/{contractId}/spotprice
     * @secure
     */
    spotpriceDetail: (
      contractId: string,
      query?: {
        /**
         * For a specific year within the contract period.
         * @format int32
         */
        year?: number
        /**
         * From value. Will use the UTC value. Example: "2024-01-21T14:00:00Z".
         * @format date-time
         */
        from?: string
        /**
         * Optional to value. Will use the UTC value. Default is tomorrow.
         * @format date-time
         */
        to?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SpotPricesDto, ProblemDetails | ValidationProblemDetails>({
        path: `/contract/${contractId}/spotprice`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weather
     * @name WeatherForecastDetail
     * @summary Get weather forecast from the location of the metering point
     * @request GET:/contract/{contractId}/weather/forecast
     * @secure
     */
    weatherForecastDetail: (
      contractId: string,
      query?: {
        /** @format int32 */
        year?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<WeatherForecastDto, ValidationProblemDetails>({
        path: `/contract/${contractId}/weather/forecast`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  bankaccountnumber = {
    /**
     * @description Get stored bankAccountnumbers
     *
     * @tags BankAccount
     * @name ImportList
     * @summary Import bank accounts
     * @request GET:/bankaccountnumber/import
     * @secure
     */
    importList: (params: RequestParams = {}) =>
      this.request<BankAccountNumberEntity[], ValidationProblemDetails>({
        path: `/bankaccountnumber/import`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  web = {
    /**
     * @description Allows client to show a list of customers that the user has access to
     *
     * @tags Customer
     * @name CustomerList
     * @summary Get a list of Person and Organization customers that the user has access to
     * @request GET:/web/customer
     * @secure
     */
    customerList: (
      query?: {
        /** @format int32 */
        PageNumber?: number
        /** @format int32 */
        PageSize?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ClientCustomerDto[], ValidationProblemDetails>({
        path: `/web/customer`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows client to create one or multiple event(s) related to opening or closing the Han Port of the meter. Allows batch creating HanPortEvent by providing a list of ContractId
     *
     * @tags HanPort
     * @name HanporteventCreate
     * @summary Add a new batch HanPortEvent
     * @request POST:/web/hanportevent
     * @secure
     */
    hanporteventCreate: (data: HanPortEventBatchIncomingDto, params: RequestParams = {}) =>
      this.request<HanPortEventOutgoingDto[], ProblemDetails>({
        path: `/web/hanportevent`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  earthfault = {
    /**
     * No description
     *
     * @tags EarthFault
     * @name ConnectiontypesList
     * @summary Get a list of earth fault connection types
     * @request GET:/earthfault/connectiontypes
     * @secure
     */
    connectiontypesList: (params: RequestParams = {}) =>
      this.request<EarthFaultConnectionTypesDto, ValidationProblemDetails>({
        path: `/earthfault/connectiontypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EarthFault
     * @name CausetypesList
     * @summary Get a list of earth fault cause types
     * @request GET:/earthfault/causetypes
     * @secure
     */
    causetypesList: (
      query: {
        connectionTypeCode: string
      },
      params: RequestParams = {}
    ) =>
      this.request<EarthFaultCauseTypesDto, ValidationProblemDetails>({
        path: `/earthfault/causetypes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EarthFault
     * @name RoomtypesList
     * @summary Get a list of earth fault room types
     * @request GET:/earthfault/roomtypes
     * @secure
     */
    roomtypesList: (
      query: {
        connectionTypeCode: string
      },
      params: RequestParams = {}
    ) =>
      this.request<EarthFaultRoomTypesDto, ValidationProblemDetails>({
        path: `/earthfault/roomtypes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  form = {
    /**
     * @description Gets a list with all form data since a given time, If no "dateTimeSince" is given, all form data is returned. <param name="dateTimeSince"> </br>dateTimeSince example: 2010-02-17T12:15:09.396Z</param>
     *
     * @tags Form
     * @name FormList
     * @summary Get a list of form data since a given time
     * @request GET:/form
     * @secure
     */
    formList: (
      query?: {
        /** @format date-time */
        dateTimeSince?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<FormDataGetDto[], ProblemDetails>({
        path: `/form`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds a new form data object with a file attachment from client
     *
     * @tags Form
     * @name FormCreate
     * @summary Add form data with file attachment
     * @request POST:/form
     * @secure
     */
    formCreate: (
      query: {
        formValue: FormDataCreateDto
      },
      data: {
        files?: File[]
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ProblemDetails>({
        path: `/form`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Gets an excel list with all elvia fondet applications since a given time, If no "dateTimeSince" is given, all form data is returned. <param name="dateTimeSince"> </br>dateTimeSince example: 2010-02-17T12:15:09.396Z</param>
     *
     * @tags Form
     * @name ElviaFondetList
     * @summary Get a list of elvia-fondet applications as excel since a given time
     * @request GET:/form/elvia-fondet
     * @secure
     */
    elviaFondetList: (
      query?: {
        /** @format date-time */
        dateTimeSince?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<FormDataGetDto[], ProblemDetails>({
        path: `/form/elvia-fondet`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the form data object for a given form Id
     *
     * @tags Form
     * @name FormDetail
     * @summary Get form data for a given Id
     * @request GET:/form/{id}
     * @secure
     */
    formDetail: (id: string, params: RequestParams = {}) =>
      this.request<FormDataGetDto[], ProblemDetails>({
        path: `/form/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  v2 = {
    /**
     * @description Download a specific invoice as a application/pdf stream response. Requires valid Bearer token.
     *
     * @tags Invoice
     * @name CustomerContractInvoicePdfDetail
     * @summary Download a specific invoice as a application/pdf stream response.
     * @request GET:/v2/customer/{customerId}/contract/{contractId}/invoice/{invoiceId}/pdf
     * @secure
     */
    customerContractInvoicePdfDetail: (
      customerId: string,
      contractId: string,
      invoiceId: string,
      query?: {
        origin?: OriginEnum
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/v2/customer/${customerId}/contract/${contractId}/invoice/${invoiceId}/pdf`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MeterValue
     * @name ContractMetervalueDetail
     * @summary Get metervalues for a contract for a given timespan.
     * @request GET:/v2/contract/{contractId}/metervalue
     * @secure
     */
    contractMetervalueDetail: (
      contractId: string,
      query: {
        /**
         * From date. Use (Norwegian timezone) full months for correct MaxHour calculations. Example: 2021-12-31T23:00:00Z
         * @format date-time
         */
        from: string
        /**
         * >To date. Use (Norwegian timezone) full months for correct MaxHour calculations. Example: 2022-01-31T23:00:00Z
         * @format date-time
         */
        to: string
        /**
         * Include empty values until end of next day.
         * @default false
         */
        includeEmptyValues?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<MeterValueV2Dto, ValidationProblemDetails>({
        path: `/v2/contract/${contractId}/metervalue`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  manualReadings = {
    /**
     * No description
     *
     * @tags ManualReading
     * @name ManualReadingsList
     * @summary Get endpoint for Sesam. Gets all manual readings older than date specified
     * @request GET:/manualReadings
     * @secure
     */
    manualReadingsList: (
      query?: {
        /**
         * example: 2010-02-17T12:15:09.396Z
         * @format date-time
         */
        since?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ManualReading[], ProblemDetails>({
        path: `/manualReadings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  manualReading = {
    /**
     * No description
     *
     * @tags ManualReading
     * @name ManualReadingCreate
     * @summary Post endpoint for sesam. Add historic manual reading.
     * @request POST:/manualReading
     * @secure
     */
    manualReadingCreate: (data: HistoricManualReadingDto, params: RequestParams = {}) =>
      this.request<ManualReading, ProblemDetails>({
        path: `/manualReading`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  enrolledOrganizations = {
    /**
     * No description
     *
     * @tags OrgAuth
     * @name EnrolledOrganizationsList
     * @summary Get a list of organizations that has enabled data sharing
     * @request GET:/enrolledOrganizations
     * @secure
     */
    enrolledOrganizationsList: (params: RequestParams = {}) =>
      this.request<EnrolledOrganizationDto[], ValidationProblemDetails | ProblemDetails>({
        path: `/enrolledOrganizations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  productionsharingcontract = {
    /**
     * No description
     *
     * @tags ProductionSharing
     * @name ProductionsharingcontractDetail
     * @summary Get production sharing contract
     * @request GET:/productionsharingcontract/{productionSharingContractId}
     * @secure
     */
    productionsharingcontractDetail: (productionSharingContractId: string, params: RequestParams = {}) =>
      this.request<ProductionSharingContractDto, ValidationProblemDetails>({
        path: `/productionsharingcontract/${productionSharingContractId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a production sharing contract between one or multiple production metering points and one or multiple consumption metering points
     *
     * @tags ProductionSharing
     * @name ProductionsharingcontractUpdate
     * @summary Update a production sharing contract
     * @request PUT:/productionsharingcontract/{productionSharingContractId}
     * @secure
     */
    productionsharingcontractUpdate: (
      productionSharingContractId: string,
      data: ProductionSharingOrderDto,
      params: RequestParams = {}
    ) =>
      this.request<ProductionSharingOrderMessage, ValidationProblemDetails>({
        path: `/productionsharingcontract/${productionSharingContractId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductionSharing
     * @name ProductionsharingcontractDelete
     * @summary Delete production sharing contract
     * @request DELETE:/productionsharingcontract/{productionSharingContractId}
     * @secure
     */
    productionsharingcontractDelete: (productionSharingContractId: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/productionsharingcontract/${productionSharingContractId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Create a new production sharing contract between one or multiple production metering points and one or multiple consumption metering points
     *
     * @tags ProductionSharing
     * @name ProductionsharingcontractCreate
     * @summary Create a new production sharing contract
     * @request POST:/productionsharingcontract
     * @secure
     */
    productionsharingcontractCreate: (data: ProductionSharingOrderDto, params: RequestParams = {}) =>
      this.request<ProductionSharingOrderMessage, ValidationProblemDetails>({
        path: `/productionsharingcontract`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  user = {
    /**
     * @description Get information about logged in user. Latest activity, and all AccessRequests submitted by the user, grouped by a combination of sender's name, subIdCustomerSendingRequest and mobileNumberSender. Used by web.
     *
     * @tags User
     * @name UserList
     * @summary Get information about the logged in user.
     * @request GET:/user
     * @secure
     */
    userList: (params: RequestParams = {}) =>
      this.request<UserDto, ValidationProblemDetails>({
        path: `/user`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes all CustomerAccess, ContractAccess, and all sent access requests created by user.
     *
     * @tags User
     * @name UserDelete
     * @summary Delete data created by the logged in user.
     * @request DELETE:/user
     * @secure
     */
    userDelete: (params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/user`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
}
