import React, { ComponentType, PropsWithChildren, useEffect } from 'react'
import { useAuth } from 'react-oidc-context'

interface CallbackComponentProps {
  successCallback: (redirectPath: string) => void
  errorCallback: (error: Error) => void
}

interface UserState {
  redirectPath: string
}

export const CallbackComponent: ComponentType<
  PropsWithChildren<CallbackComponentProps>
> = ({ successCallback, errorCallback, children }) => {
  const { isAuthenticated, isLoading, user, error } = useAuth()

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      successCallback((user?.state as UserState)?.redirectPath)
    }

    if (error) {
      errorCallback(error)
    }
  }, [isAuthenticated, isLoading, error])

  return <>{children}</>
}
