import { CustomerType, useSelectedCustomer } from '@elvia/kundeportal-api'

type CustomerCategory =
  | 'Faktura'
  | 'Målepunkt'
  | 'HAN-port'
  | 'Tilganger'
  | 'Produksjonsdeling'
  | 'Jordfeil'
  | 'Varslingsinnstillinger'

type UserCategory = 'Søkeresultat' | 'Søkeord'

type Category =
  | `${'Bedrift' | 'Privat'} - ${CustomerCategory}`
  | `${'Kunde' | 'Proff'} - ${UserCategory}`
  | 'Bruker'
  | 'Innhold'

interface SiteimproveEvent {
  category: Category | CustomerCategory
  action: string
  label?: string
}

interface SiteimproveWindow extends Window {
  _sz?: string[][]
}

const pushSiteimproveEvent = (event: SiteimproveEvent): void => {
  if (window) {
    const w = window as SiteimproveWindow
    w._sz?.push(['event', event.category, event.action, event?.label || ''])
  }
}

const addCustomerTypeToCategory = (
  category: CustomerCategory,
  customerType?: CustomerType
): Category | CustomerCategory => {
  if (!customerType) return category
  return customerType === 'Person'
    ? `Privat - ${category}`
    : `Bedrift - ${category}`
}

const addUserTypeToCategory = (category: UserCategory) => {
  return `${
    window.location.pathname.startsWith('/proff/') ? 'Proff' : 'Kunde'
  } - ${category}` as const
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSiteImprove = () => {
  const { data: selectedCustomer } = useSelectedCustomer()
  const customerType = selectedCustomer?.type

  return {
    userLoggedInEvent: () =>
      pushSiteimproveEvent({
        category: 'Bruker',
        action: 'Logget inn',
      }),
    downloadInvoiceEvent: (period: string) =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Faktura', customerType),
        action: 'Last ned PDF',
        label: period,
      }),
    sendBankAccountNumberEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Faktura', customerType),
        action: 'Lagre kontonummer',
      }),
    sendManualReadingEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Målepunkt', customerType),
        action: 'Send måleravlesning',
      }),
    downloadConsumptionEvent: (type: string) =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Målepunkt', customerType),
        action: 'Last ned forbruk',
        label: type,
      }),
    clickKeyFiguresEvent: (keyFigure: string) =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Målepunkt', customerType),
        action: 'Klikk på nøkkeltall',
        label: keyFigure || '',
      }),
    clickOnTariffInfoTabsEvent: (tab: string) =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Målepunkt', customerType),
        action: 'Klikk på tariff-info fane',
        label: tab,
      }),
    clickOnAdvancedGraphViewEvent: (view: string) =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Målepunkt', customerType),
        action: 'Klikk på avansert grafvisning',
        label: view,
      }),
    openHanPortEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('HAN-port', customerType),
        action: 'Bestill åpning',
      }),
    closeHanPortEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('HAN-port', customerType),
        action: 'Bestill lukking',
      }),
    generateTokenEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Tilganger', customerType),
        action: 'Opprett token',
      }),
    sendCorporateAccessGrantEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Tilganger', customerType),
        action: 'Gi bedriftstilgang',
      }),
    sendPrivateAccessGrantEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Tilganger', customerType),
        action: 'Gi privattilgang',
      }),
    sendPrivateAccessRequestEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Tilganger', customerType),
        action: 'Be om privattilgang',
      }),
    submitDataportabilityEvent: (organization: string) =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Tilganger', customerType),
        action: 'Opprett datadeling',
        label: organization,
      }),
    deleteAccessEvent: (accessRole: string) =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Tilganger', customerType),
        action: 'Slett tilgang',
        label: accessRole,
      }),
    sendProductionSharingContractEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Produksjonsdeling', customerType),
        action: 'Opprett produksjonsdelingsavtale',
      }),
    editProductionSharingContractEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Produksjonsdeling', customerType),
        action: 'Endre produksjonsdelingsavtale',
      }),
    deleteProductionSharingContractEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Produksjonsdeling', customerType),
        action: 'Avslutt produksjonsdelingsavtale',
      }),
    playVideoEvent: (title: string) =>
      pushSiteimproveEvent({
        category: 'Innhold',
        action: 'Spill av video',
        label: title,
      }),
    submitFormEvent: (href: string) =>
      pushSiteimproveEvent({
        category: 'Innhold',
        action: 'Send skjema',
        label: href,
      }),
    clickOnSearchResultEvent: (href: string, searchInput: string) =>
      pushSiteimproveEvent({
        category: addUserTypeToCategory('Søkeresultat'),
        action: href,
        label: searchInput,
      }),
    searchEvent: (searchInput: string, resultCount: number) =>
      pushSiteimproveEvent({
        category: addUserTypeToCategory('Søkeord'),
        action: searchInput,
        label: `${resultCount} treff`,
      }),
    submitEarthFaultExtendPeriodeEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Jordfeil', customerType),
        action: 'Utvid periode for jordfeil',
      }),
    submitEarthFaultRectifyEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Jordfeil', customerType),
        action: 'Rettemelding for jordfeil',
      }),
    earthFaultDetectedEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory('Jordfeil', customerType),
        action: 'Jordfeil registrert hos måler',
      }),
    updateNotificationPreferenceEvent: () =>
      pushSiteimproveEvent({
        category: addCustomerTypeToCategory(
          'Varslingsinnstillinger',
          customerType
        ),
        action: 'Oppdater varslingsinnstilling',
      }),
  }
}
