import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { createContext, FC, ReactNode, useContext, useMemo } from 'react'

import { useAuth } from '@elvia/elvid-provider'

import { Api } from './kundeportal-api'

interface ApiProviderProps {
  children?: ReactNode
}

const ApiContext = createContext<Api<unknown> | undefined>(undefined)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000,
    },
  },
})

export const ApiProvider: FC<ApiProviderProps> = ({ children }) => {
  const { user } = useAuth()
  const token = user?.access_token
  const api = useMemo(() => {
    const kundePortalApi = new Api()
    kundePortalApi.instance.interceptors.request.use(config => {
      if (token) {
        config.baseURL = process.env.KUNDEPORTAL_API_URL
        config.timeout = 200000
        if (config.headers) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }

      return config
    })

    return kundePortalApi
  }, [token])

  return (
    <ApiContext.Provider value={api}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
        {children}
      </QueryClientProvider>
    </ApiContext.Provider>
  )
}

export const useKundeportalApi = (): Api<unknown> => {
  const context = useContext(ApiContext)
  if (context === undefined) {
    throw new Error('useKundeportalApi must be used within a ApiProvider')
  }
  return context
}

export const invalidateQueries = (
  ...params: Parameters<typeof queryClient.invalidateQueries>
): ReturnType<typeof queryClient.invalidateQueries> => {
  return queryClient.invalidateQueries(...params)
}
