import React from 'react'

import { initSalesforce } from './src/salesforce'
import { SalesforceChatButton } from './src/SalesforceChatButton'

export const onClientEntry = () => {
  initSalesforce()
}

export const wrapPageElement = ({ element, props }, config) => {
  return (
    <>
      {!config.blackList.includes(props.path) && <SalesforceChatButton />}
      {element}
    </>
  )
}
