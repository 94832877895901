import { FC, useEffect } from 'react'

export enum ChatStatuses {
  Idle = 'idle',
  Pending = 'pending',
  Opened = 'opened',
}

const moveChat = () => {
  const salesforceButton = window.document.getElementById(
    'embeddedMessagingConversationButton'
  )
  const salesforceButtonContainer = window.document.getElementById(
    'embeddedMessagingFrame'
  )
  const fixedBottomContainer = window.document.getElementById(
    'fixed-gatsby-container'
  )
  if (!fixedBottomContainer) return

  const fixedBottomContainerHeight =
    fixedBottomContainer.getBoundingClientRect().height

  salesforceButton?.style.setProperty(
    'bottom',
    `calc(${fixedBottomContainerHeight}px + 1.5rem)`,
    'important'
  )

  salesforceButtonContainer?.style.setProperty(
    'bottom',
    `${fixedBottomContainerHeight}px`,
    'important'
  )
}

export const SalesforceChatButton: FC = () => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(moveChat)
    const fixedBottomContainer = window.document.getElementById(
      'fixed-gatsby-container'
    )

    if (fixedBottomContainer) {
      resizeObserver.observe(fixedBottomContainer)
    }
    window.addEventListener('message', moveChat)

    return () => {
      resizeObserver.disconnect()
      window.removeEventListener('message', moveChat)
    }
  }, [])

  return null
}
