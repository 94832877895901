import {
  IAutoExceptionTelemetry,
  ICustomProperties,
  IEventTelemetry,
  SeverityLevel,
} from '@microsoft/applicationinsights-web'
import { appInsights } from './AppInsightsProvider'

export const trackException = (
  exception: Error | IAutoExceptionTelemetry,
  customProperties?: ICustomProperties
): void => {
  appInsights.trackException(
    {
      exception: exception,
      severityLevel: SeverityLevel.Error,
    },
    customProperties
  )
}

export const trackEvent = (
  event: IEventTelemetry,
  customProperties?: ICustomProperties
): void => {
  appInsights.trackEvent(event, customProperties)
}
